<template>
  <div class="m-collapse__group">
    <h2 class="m-collapse__group-title a-h3" v-if="props.title" v-text="props.title"></h2>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">import { provide, ref } from 'vue'


const props = defineProps({
  leaveOpen: {
    type: Boolean,
    default: false,
  },
  title: String,
})

const openElement = ref('')

const setOpenElement = (id: string) => {
  openElement.value = id
}

provide('element', { openElement, setOpenElement })
</script>
