<template v-if="!loading">
  <transition>
    <template v-if="collectionFilterStore.isMobileFiltersOpen">
      <BaseFilterPopup @close="collectionFilterStore.toggleMobileFilters()">
        <template #activeFilters>
          <template v-for="filter in Object.values(collectionFilterStore.getActiveFilters())">
            <ActiveFilter :filter-name="filter.name" :internal-name="filter.internal_name" :filter-option="option"
              v-for="option in filter.options" />
          </template>
        </template>
        <template #filters>
          <BaseFilters :filters="props.filters" :mobile="true" />
        </template>
      </BaseFilterPopup>
    </template>
  </transition>
</template>
<script setup lang="ts">
import BaseFilterPopup from './BaseFilterPopup.vue';
import { FilterRaw, useCollectionFilters } from '../../stores/collection';
import { onMounted, Ref, ref } from 'vue';
import BaseFilters from './BaseFilters.vue';
import ActiveFilter from './ActiveFilter.vue';

let collectionFilterStore = useCollectionFilters()

const props = defineProps({
  filters: {
    type: Array<FilterRaw>,
    required: true,
  },
})
let loading: Ref<boolean> = ref(true)

onMounted(() => {
  collectionFilterStore.init(props.filters)
  loading.value = false
})

</script>
