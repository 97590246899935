import { set } from "@vueuse/core";
import { defineStore } from "pinia"
import { ref, Ref } from "vue"
// Raw filter data from the backend.
export interface FilterRaw {
    name: string;
    internal_name: string;
    options: Array<RawFilterOption>;
    columns: number
    type: 'Default' | 'Color';
}

// Raw filter option data from the backend.
export interface RawFilterOption {
    id: string
    name: string
    attribute_values: Array<string>
    data: any
    active: boolean
}

export const useCollectionFilters = defineStore('collection-filters', () => {
    const loaded: Ref<Boolean> = ref(false)
    const isMobileFiltersOpen: Ref<Boolean> = ref(false)
    const allfilterOptions: Ref<Record<string, FilterRaw>> = ref({})
    const isChanged: Ref<boolean> = ref(false)
    const searchTerm: Ref<string> = ref('')
    const collection: Ref<string> = ref('')
    function init(filters: FilterRaw[]) {
        if (loaded.value) {
            return;
        }

        filters.forEach(filter => {
            if (typeof allfilterOptions.value[filter.internal_name] === 'undefined') {
                allfilterOptions.value[filter.internal_name] = filter
            }
        })
        let currentUrl = new URL(window.location.href)

        setSearchTerm(currentUrl.searchParams.get('term') ?? '')
        setCollection(currentUrl.searchParams.get('collection') ?? '')

        isChanged.value = false
        loaded.value = true
    }

    function getActiveFilters(): Record<string, FilterRaw> {
        let activeFilters: Record<string, FilterRaw> = {}
        for (let [key, value] of Object.entries(allfilterOptions.value)) {
            let activeOptions = value.options.filter(option => option.active)
            if (activeOptions.length === 0) {
                continue;
            }

            activeFilters[key] = {
                name: value.name,
                internal_name: value.internal_name,
                options: activeOptions,
                columns: value.columns,
                type: value.type,
            }
        }

        return activeFilters
    }

    function toggleMobileFilters() {
        isMobileFiltersOpen.value = !isMobileFiltersOpen.value
    }

    function getUrlForFilters(): string | null {
        if (!isChanged.value) {
            return null
        }

        let currentUrl = new URL(window.location.href)
        let routeParameters: Record<string, string[]> = {}
        let currentKeys = Array.from(currentUrl.searchParams.keys())
        for (let key of currentKeys) {
            if (!key.startsWith('filter_') && key !== 'collection' && key !== 'term') {
                continue
            }

            currentUrl.searchParams.delete(key)
        }

        if (currentKeys.includes('page_number')) {
            currentUrl.searchParams.delete('page_number')
        }

        let activeFilters = getActiveFilters()
        for (let [key, value] of Object.entries(activeFilters)) {
            routeParameters[key] = value.options.map(k => k.id)
        }

        for (let [key, value] of Object.entries(routeParameters)) {
            currentUrl.searchParams.set(key, value.join(','))
        }

        if (searchTerm.value.length > 0) {
            currentUrl.searchParams.set('term', searchTerm.value)
        }

        if (collection.value.length > 0) {
            currentUrl.searchParams.set('collection', collection.value)
        }

        return currentUrl.toString()
    }

    function getReplacedUrl(replacements: {[key: string]: any}): string {
        let currentUrl = new URL(window.location.href)
        for (let [key, value] of Object.entries(replacements)) {
            if (value === null) {
                currentUrl.searchParams.delete(key)
                continue;
            }

            currentUrl.searchParams.set(key, value)
        }

        return currentUrl.toString()
    }


    function setCollection(collectionParam: string) {
        collection.value = collectionParam
    }

    function setSearchTerm(term: string) {
        searchTerm.value = term
    }

    function resetFilter() {
        for (let filter of Object.values(allfilterOptions.value)) {
            for (let option of filter.options) {
                if (option.active === false) {
                    continue;
                }

                option.active = false
            }
        }

        setCollection('')
        setSearchTerm('')

        isChanged.value = true
    }

    function toggleFilterOption(internalName: string, id: string) {
        let filter = allfilterOptions.value[internalName] ?? null;
        if (filter === null) {
            return;
        }

        let option = filter.options.find(option => option.id === id)
        if (typeof option === 'undefined') {
            return;
        }

        option.active = !option.active
        isChanged.value = true
    }

    return {
        init,
        isMobileFiltersOpen,
        toggleMobileFilters,
        getActiveFilters,
        allfilterOptions,
        isChanged,
        getUrlForFilters,
        resetFilter,
        toggleFilterOption,
        setCollection,
        setSearchTerm,
        getReplacedUrl,
    }
})
