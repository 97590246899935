<template>
  <div class="m-dropdown" @mouseleave="close" ref="root">
    <component :is="props.url ? 'a' : 'button'" :href="props.url ? props.url : null" class="m-header-nav__link" :class="{ 'is-active': isOpen }" @mouseenter="open" @touchStart="open" @keydown.enter.prevent="toggle" @keydown.esc.prevent="close"
      :aria-expanded="isOpen ? 'true' : 'false'">
      <span class="m-dropdown__label" v-text="props.title"></span>
    </component>

    <transition>
      <div v-show="showDropdown && isOpen" ref="content" class="m-dropdown__content" tabindex="-1" >
        <a v-if="props.url" class="u-skip-link" :href="url">Navigeer naar {{ props.title }}</a>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref } from 'vue'


const props = defineProps({
  showDropdown: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    default: null,
  },
})

const isOpen = ref(false)
const touchEvent = ref("touchstart")
const content = ref<any>()
const root = ref<HTMLElement | null>(null)

onMounted(() => {
  document.addEventListener("touchstart", handleEventOutside)
  document.addEventListener("focusin", handleEventOutside)
})

onUnmounted(() => {
  document.removeEventListener("touchstart", handleEventOutside)
  document.removeEventListener("focusin", handleEventOutside)
})

const open = () => {
  isOpen.value = true
  window.addEventListener("keydown", keyDownHandler, true)
  touchEvent.value = ''

  nextTick(() => {
    content?.value?.focus()
  })
}


const close = () => {
  window.removeEventListener("keydown", keyDownHandler, true)
  isOpen.value = false
  touchEvent.value = "touchstart"
}

const toggle = () => {
  isOpen.value ? close() : open()
}

const handleEventOutside = (e: any) => {
  if (!root.value!.contains(e.target)) {
    close()
  }
}

const keyDownHandler = (e: any) => {
  e.key === 'Esc' || e.key === 'Escape' ? close() : null
}
</script>
