export interface Product {
    ID: string
    name: string
    quantity: number
    price: number
    attributes: Record<string, string>;
    image_url: string,
}

export interface ProductData {
    ID: string
    type: 'ProductTypeSimple' | 'ProductTypeConfiguable'
    name: string
    displayName: string
    sku: string
    description: string
    // Be carefull it can be a string so you might need to explicitly cast it to number.
    price: number|string
    // Be carefull it can be a string so you might need to explicitly cast it to number.
    originalPrice: number|string
    media: Media[]
    variants: Variant[]
    attributes: { [key: string]: Attribute }
    url: string
    available: boolean
    tags: string[]
}

export function hasSale(product: {price: number|string
    originalPrice: number|string}): boolean {
    let price: number = Number(product.price)
    let originalPrice: number = Number(product.originalPrice)
    return price < originalPrice
}

export interface Media {
    thumbUrl: string
    sourceUrl: string
    title: string
    alt: string
}

export interface Variant {
    ID: string
    name: string
    price: number|string
    originalPrice: number|string
    media: Media[]
    attributes: { [key: string]: Attribute }
    url: string
    available: boolean
}

export interface Attribute {
    name: string
    value: string
    visibility: AttributeVisibility[]
}

export enum AttributeVisibility {
    // TODO: Remove AttributeSetVisibilityCatalog and AttributeSetVisibilitySales when php is gone.
    CatalogGo = 'catalog',
    Catalog = 'AttributeSetVisibilityCatalog',
    Sales = 'AttributeSetVisibilitySales',
}

export enum ProductType {
    Simple = 'ProductTypeSimple',
    Configurable = 'ProductTypeConfiguable',
}
