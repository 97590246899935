<template>
  <div v-if="newsletterTranslation" class="o-component o-component--newsletter">
    <div class="m-newsletter">
      <div class="o-container a-layout">
        <div class="u-col-span:6@md">
          <div class="m-newsletter__info">
            <h2 class="m-newsletter__title">{{ newsletterTranslation.title }}</h2>
            <div class="m-newsletter__info o-editor-content o-editor-content--newsletter">
              <p>{{ newsletterTranslation.description }}</p>
            </div>
          </div>
        </div>
        <div class="u-col-span:6@md">
          <div class="m-newsletter__form-alert" role="alert">
            <span v-if="subscribedToNewsletter && errorMessage === ''" class="m-newsletter__alert--good">{{
              translate('newsletter.message.success') }}</span>
            <span v-if="!subscribedToNewsletter && errorMessage !== ''" class="m-newsletter__alert--error">{{ errorMessage
            }}</span>
          </div>
          <form ref="form" method="post" :action="props.endpoint" class="m-newsletter__form" @submit="submitForm($event)"
            v-if="!subscribedToNewsletter">
            <label for="email" class="u-screen-reader-only">{{ newsletterTranslation.label }}</label>
            <input class="m-newsletter__input" @focus="loadRecaptcha($event)" type="email"
              :placeholder="newsletterTranslation.placeholder" id="email" name="email" autocomplete="email"
              required />
            <button v-if="!loading" class="m-newsletter__btn" type="submit" >
              {{ newsletterTranslation.button_text }}
            </button>
          </form>
          <div class="m-newsletter__disclaimer o-editor-content o-editor-content--disclaimer"
            v-if="!subscribedToNewsletter">
            <p> {{ newsletterTranslation.disclaimer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Ref, ref, onMounted } from 'vue'
import { translate } from '../helper-functions'
import { useCookies } from "vue3-cookies";
import { useRecaptchaStore } from '../stores/recaptcha';

interface NewsletterTranslation {
  button_text: string
  description: string
  disclaimer: string
  label: string
  placeholder: string
  title: string
}

const props = defineProps({
  endpoint: {
    type: String,
    required: true,
  },
  recaptchaSiteKey: {
    type: String,
    required: true,
  },
  newsletterText: {
    default: null,
  },
  enabled: {
    default: false,
  }
})
const newsletterTranslation: NewsletterTranslation = typeof props.newsletterText === 'string' ? JSON.parse(props.newsletterText) : JSON.parse(JSON.stringify(props.newsletterText));
const cookieApi = useCookies()
const errorMessage: Ref<string> = ref('');
const form: Ref<HTMLFormElement | null> = ref(null)
const newsletterCookieKey: string = 'newsletter-subscribed';
let loading: Ref<boolean> = ref(true)
let subscribedToNewsletter: Ref<boolean> = ref(false)
const recaptcha = useRecaptchaStore()
function loadRecaptcha($event: Event) {
  recaptcha.init(props.recaptchaSiteKey)
}

onMounted(() => {
  // Set initial value if the expected cookie is set!
  subscribedToNewsletter.value = cookieApi.cookies.get(newsletterCookieKey) === '1';
  // Retister function to submit the form to javascript window global as 'sendNewsletterForm'
  // so google recaptcha can use the registerered callback with that name.
  (window as any).sendNewsletterForm = submitForm;
  loading.value = false
})

async function submitForm($event: Event) {
  $event.preventDefault()

  loading.value = true
  errorMessage.value = ''
  let recapthcaToken = await recaptcha.getRecaptchaToken('newsletterSubmit')
  const serializedFormData = getSerializedFormData(form.value!)

  await fetch(props.endpoint, {
    method: form.value!.method,
    body: serializedFormData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'g-recaptcha-token': recapthcaToken,
    },
  }).then(async (resp) => {
    if (!resp.ok || resp.headers.get('captchaError') === '1') {
      let data = await resp.json()
      errorMessage.value = data.error ?? defaultErrorMessage()
      loading.value = false
      return
    }

    cookieApi.cookies.set(newsletterCookieKey, '1')
    subscribedToNewsletter.value = true
  }).catch(resp => {
    errorMessage.value = resp.error ?? defaultErrorMessage()
  }).finally(() => {
    loading.value = false
  })
}

function defaultErrorMessage(): string {
  return translate('newsletter.message.error')
}

function getSerializedFormData(formElement: HTMLFormElement): string {
  const formData = new FormData(formElement)
  let formDataRecord: Record<string, string> = {}
  formData.forEach((value: FormDataEntryValue, key: string) => {
    formDataRecord[key] = value.toString()
  })
  return new URLSearchParams(formDataRecord).toString()
}
</script>
