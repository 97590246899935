import { Variant } from "../models/product"

export function formatConfigurableAttributes(variant: Variant, configurableAttributes: string[]) {
  const attributes: string[] = []
  for (let internalName of configurableAttributes) {
    if (!Object.keys(variant.attributes ?? {}).includes(internalName)) {
      continue
    }

    attributes.push(variant.attributes[internalName].value)
  }

  return attributes.join(' - ')
}

type getValue<T> = (v: T) => string

// sorts items
export function sortAttributeValueList<T>(attributeValues: T[], getValue: getValue<T> = (v: T) => v + ''): T[] {
  let numberSizes: T[] = []
  let internationalClothingSizes: T[] = []
  let otherSizes: T[] = []


  for (let size of attributeValues) {
    if (getValue(size).trim().toUpperCase() in INTERNATIONAL_CLOTHING_SIZE_WEIGHTS) {
      internationalClothingSizes.push(size)
    } else if (getValue(size).match(/(^(\d+((\.|\,)\d+)?)((\/|-)(\d+((\.|\,)\d+)?))?$)/)) {
      numberSizes.push(size)
    } else {
      otherSizes.push(size)
    }
  }

  numberSizes.sort((a, b) => sortNumberSizes(a, b, getValue))
  internationalClothingSizes.sort((a, b) => sortInternationalProductSizes(a, b, getValue))
  otherSizes.sort((a, b) => getValue(a).localeCompare(getValue(b)))

  return [...internationalClothingSizes, ...numberSizes, ...otherSizes]
}

function sortInternationalProductSizes<T>(a: T, b: T, formatToString: getValue<T>): number {
  let aWeight: number = INTERNATIONAL_CLOTHING_SIZE_WEIGHTS[formatToString(a).trim().toUpperCase()] ?? 0
  let bWeight: number = INTERNATIONAL_CLOTHING_SIZE_WEIGHTS[formatToString(b).trim().toUpperCase()] ?? 0
  if (aWeight === bWeight) {
    return 0
  }

  return aWeight > bWeight ? 1 : -1
}

function sortNumberSizes<T>(a: T, b: T, formatToString: getValue<T>): number {
  const valA = formatSizeNumber(formatToString(a))
  const valB = formatSizeNumber(formatToString(b))

  let aFloat: number = parseFloat(valA)
  let bFloat: number = parseFloat(valB)

  if (aFloat === bFloat) {
    return 0
  }

  return aFloat < bFloat ? -1 : 1
}

function formatSizeNumber(val: string): string {
  if (val.match(/(\d+\,\d+)/)) {
    val = val.replace(/,/, '.')
  }

  if (val.match(/(^\d+(\.\d+)?)(\/|-)(\d+(\.\d+)?$)/)) {
    val = val.replace(/(\/|-).*$/, '')
  }

  return val
}

const INTERNATIONAL_CLOTHING_SIZE_WEIGHTS: Record<string, number> = {
  "3XS": 0,
  "XXXS": 0,
  "2XS": 1,
  "XXS": 1,
  "XS": 2,
  "S": 3,
  "M": 4,
  "L": 5,
  "XL": 6,
  "XXL": 7,
  "2XL": 7,
  "XXXL": 8,
  "3XL": 8,
  "XXXXL": 9,
  "4XL": 9,
  "XXXXXL": 10,
  "5XL": 10
}