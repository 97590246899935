<template>
  <li
    class="m-product-list__item"
    :class="{ 'active': props.collection.active_tree, 'empty': props.collection.empty }"
  >
    <a
      :href="url" class="m-product-list__item-link"
      :class="{ 'active': props.collection.active, 'empty': props.collection.empty }"
      :aria-current="props.collection.active ? 'true' : 'false'"
    >
      <span>{{ props.collection.name }}</span>
    </a>

    <ul
      class="m-product-list__sub"
      v-if="props.collection.items.length > 0 && collapsed"
    >
      <recursive-collection-list
        :search-enabled="searchEnabled"
        v-for="item in props.collection.items"
        :key="item.url"
        :collection="item"
      >
      </recursive-collection-list>
    </ul>
  </li>
</template>
<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { useCollectionFilters } from '../../stores/collection';

interface Collection {
  ID: string,
  name: string,
  active_tree: Boolean,
  active: Boolean,
  empty: Boolean,
  url: string,
  items: Collection[],
}

let collapsed: Ref<Boolean> = ref(false);
let url: Ref<string> = ref('');

onMounted(() => {
  collapsed.value = props.collection.active_tree;
  url.value = getUrl();
})

const collectionFilterStore = useCollectionFilters()

const props = defineProps({
  collection: {
    type: Object as () => Collection,
    required: true,
  },
  searchEnabled: {
    type: Boolean,
    required: true,
  }
})

function getUrl() {
  if (props.searchEnabled) {
    if (props.collection.active) {
      return collectionFilterStore.getReplacedUrl({ collection: null })
    }
    return collectionFilterStore.getReplacedUrl({ collection: props.collection.ID })
  }

  return !props.collection.empty ? props.collection.url : ''
}
</script>
