<template>
  <nav class="o-product-overview__pagination m-pagination" v-if="totalPages > 1">
    <ul class="m-pagination__list">
      <li>
        <a v-if="activePage > 1" :href="prevURL()" rel="prev" class="m-pagination__button m-pagination__button--arrow" aria-current="false" aria-label="Previous page" aria-disabled="false">
          <svg class="a-icon a-icon--pagination" aria-hidden="true">
            <use href="#chevron-left" />
          </svg>
        </a>
      </li>

      <li><a :href="pageURL(1)" class="m-pagination__button u-visible--flex@md" :aria-current="activePage === 1 ? 'true' : 'false'" :class="{ 'current': activePage === 1 }" aria-disabled="false">1</a></li>
      <li><a v-if="activePage < 3" :href="pageURL(1)" class="m-pagination__button u-hidden--flex@md" :class="{ 'current': activePage === 1 }" aria-current="false" aria-disabled="false">1</a></li>

      <!-- Begin dots of the pagination -->
      <li><span v-if="activePage > 3" class="m-pagination__button m-pagination__button--dots u-visible--flex@md" aria-current="false" aria-disabled="false">...</span></li>

      <template v-for="page in getPaginationOptions(activePage)" :key="page">
        <li v-if="(page > 1 && page < totalPages)">
          <a :href="pageURL(page)" class="m-pagination__button" :class="{ 'current': page === activePage }" :aria-current="page === activePage ? 'true' : 'false'" aria-disabled="false">{{ page }}</a>
        </li>
      </template>

      <!-- End dots of the pagination -->
      <li><span v-if="activePage < totalPages - 2" class="m-pagination__button m-pagination__button--dots u-visible--flex@md" aria-current="false" aria-disabled="false">...</span></li>

      <li><a :href="pageURL(totalPages)" class="m-pagination__button u-visible--flex@md" :class="{ 'current': activePage === totalPages }" :aria-current="activePage === totalPages ? 'true' : 'false'" aria-disabled="false">{{ totalPages }}</a></li>
      <li><a v-if="activePage > totalPages - 2" :href="pageURL(totalPages)" class="m-pagination__button u-hidden--flex@md" :class="{ 'current': activePage === totalPages }" aria-current="false" aria-disabled="false">{{ totalPages }}</a></li>

      <li>
        <a v-if="activePage < totalPages" :href="nextURL()" rel="next" class="m-pagination__button m-pagination__button--arrow" aria-current="false" aria-label="Next page" aria-disabled="false">
          <svg class="a-icon a-icon--pagination" aria-hidden="true">
            <use href="#chevron-right" />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
})

const activePage = ref(props.currentPage + 1)

const getPaginationOptions = (page: number) => {
  const total = props.totalPages
  switch (page) {
    case 1:
      return [2, 3]
    case 2:
      return [2, 3]
    case 3:
      return [2, 3, 4]
    case total - 3:
      return [page - 1, page, page + 1]
    case total - 2:
      return [page - 1, page, page + 1]
    case total - 1:
      return [page - 1, page]
    case total:
      return [page - 2, page - 1]
    default:
      return [page - 1, page, page + 1]
      break
  }
}

function pageURL(page: number): string {
  const cur = new URL(window.location.href)
  cur.searchParams.set('page_number', (page - 1).toString(10))
  return cur.href
}

function prevURL(): string {
  const cur = new URL(window.location.href)
  cur.searchParams.set('page_number', (props.currentPage - 1).toString(10))
  return cur.href
}

function nextURL(): string {
  const cur = new URL(window.location.href)
  cur.searchParams.set('page_number', (props.currentPage + 1).toString(10))
  return cur.href
}
</script>
