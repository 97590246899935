<template>
  <transition>
    <div class="m-cookiebar" v-if="cookieBarStore.showCookieBar" ref="cookiebar">
      <div class="m-cookiebar__content">
        <p class="m-cookiebar__text" v-text="props.text">
        </p>
        <a
          v-if="props.readMoreButtonLink !== ''"
          class="m-cookiebar__link"
          :href="props.readMoreButtonLink"
        >
          {{ props.readMoreButtonText }}
        </a>
      </div>
      <div class="m-cookiebar__action">
        <button class="a-btn m-cookiebar__btn-deny" type="button" @click="cookieBarStore.denyCookies()">
          {{ props.denyButtonText }}
        </button>
        <button class="a-btn m-cookiebar__btn-accept" ref="acceptButton" type="button" @click="cookieBarStore.acceptCookies()">
          {{ props.acceptButtonText }}
        </button>
      </div>
    </div>
  </transition>
  <transition>
    <span
      v-if="cookieBarStore.showCookieBar"
      class="a-overlay a-overlay--not-clickable"
      aria-hidden="true"
      type="button"
      ref="overlay"
    >
    </span>
  </transition>
</template>

<script setup lang="ts">
import { createFocusTrap } from 'focus-trap'
import { useCookieBar } from '../stores/cookie-bar'
import { Ref, nextTick, onMounted, ref } from 'vue'

const cookieBarStore = useCookieBar()
const cookiebar: Ref<Element | null> = ref(null)
const overlay: Ref<Element | null> = ref(null)
const acceptButton: Ref<Element | null> = ref(null)
const focusTrap: Ref<any> = ref(null)
const props = defineProps({
  text: {
    type: String,
    required: false,
    default: 'This website uses cookies to improve your experience.',
  },
  acceptButtonText: {
    type: String,
    required: false,
    default: 'Allow cookies',
  },
  denyButtonText: {
    type: String,
    required: false,
    default: 'Deny cookies',
  },
  readMoreButtonText: {
    type: String,
    required: false,
    default: 'Read more',
  },
  readMoreButtonLink: {
    type: String,
    required: false,
    default: '',
  },
})

onMounted(async () => {
  nextTick(() => {
    try {
      focusTrap.value = createFocusTrap([cookiebar.value as HTMLDivElement, overlay.value as HTMLDivElement], {
        initialFocus: acceptButton.value as HTMLElement,
      }).activate()
    } catch (e) {}
  })
})

</script>
