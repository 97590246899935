<template>
  <nav class="m-header-nav__center">
    <ul class="m-header-nav__list" aria-label="Main menu">
      <li v-for="item of props.menuItems" class="m-header-nav__item"
        :class="{ 'm-header-nav__item--sale': (item?.tags ?? []).includes('sale'), 'm-header-nav__item--new': (item?.tags ?? []).includes('new'), 'active': menuStore.isMenuItemActive(item.url) }"
        aria-expanded="false">
        <BaseNavDropdown v-if="item.items && item.items.length > 0" :title="item.name" :url="item.url" :show-dropdown="item.items.length > 0">
          <ul class="m-dropdown__list" tabindex="1" :aria-label="item.name" v-if="hasGroup(item)">
            <li v-for="group of item.items" class="m-dropdown__item">
              <a :href="group.url" class="m-dropdown__item-title" v-if="(group.items ?? []).length > 0">{{ group.name }}</a>
              <ul v-if="(group.items ?? []).length > 0"
                v-for="batch of chunk(group.items ?? [], (group.items ?? []).length > 16 ? (group.items ?? []).length / 4 : (group.items ?? []).length / 2)"
                class="m-dropdown__grid-list" :aria-label="group.name">
                <li class="m-dropdown__grid-row">
                  <ul class="m-dropdown__grid-items" :aria-label="group.name">
                    <li v-for="groupItem of batch" class="m-dropdown__grid-item">
                      <a :href="groupItem.url" class="m-dropdown__grid-link">{{ groupItem.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="m-dropdown__list" tabindex="1" :aria-label="item.name" v-if="!hasGroup(item)">
            <li class="m-dropdown__item">
              <ul v-if="(item.items ?? []).length > 0"
                v-for="batch of chunk(item.items ?? [], (item.items ?? []).length > 16 ? (item.items ?? []).length / 4 : (item.items ?? []).length / 2)"
                class="m-dropdown__grid-list">
                <li class="m-dropdown__grid-row">
                  <ul class="m-dropdown__grid-items">
                    <li v-for="batchItem of batch" class="m-dropdown__grid-item">
                      <a :href="batchItem.url" class="m-dropdown__grid-link">{{ batchItem.name }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </BaseNavDropdown>
        <a v-if="item.items === null || item.items.length == 0" :href="item.url" class="m-header-nav__link" :class="{'active': menuStore.isMenuItemActive(item.url)}">{{ item.name }}</a>
      </li>
    </ul>
  </nav>
</template>
<script setup lang="ts">
import { MenuItem, useMenuStore } from '../stores/menu'
import BaseNavDropdown from './BaseNavDropdown.vue'

const menuStore = useMenuStore();
const props = defineProps({
  menuItems: {
    type: Array<MenuItem>,
    default: [],
  },
})

// Returns if the item has grandchildren. If so we render the group name names.
function hasGroup(item: MenuItem): boolean {
  return item.items?.some((group) => group.items?.length > 0) ?? false
}

function chunk<T>(arr: Array<T>, chunkSize: number): Array<T[]> {
  var chunkedArray: Array<T[]> = []
  arr = arr ?? []
  if (arr.length === 0){
    return chunkedArray
  }
  for (var i = 0; i < arr.length; i += chunkSize) {
    chunkedArray.push(arr.slice(i, i + chunkSize))
  }

  return chunkedArray
}
</script>
