<template>
  <button v-if="!cartStore.loadingCart" type="button" class="a-btn a-btn--icon" @click="cartStore.openCart()"
    title="Shopping cart">
    <span class="a-cart">
      <svg class="a-icon a-icon--cart" aria-hidden="true">
        <use href="#bag" />
      </svg>
      <span class="a-cart__count" :class="{ 'animate-ping': cartStore.productCount > 0 }"></span>
      <span class="a-cart__count">{{ cartStore.productCount }}</span>
    </span>
    <span class="a-cart__price u-visible@md" v-text="`${displayPrice(cartStore.cart.total)} `"></span>
  </button>
  <button v-else class="a-btn a-btn--icon" type="button">
    <svg class="animate-spin a-icon a-icon--cart" aria-hidden="true">
      <use href="#arrow-path" />
    </svg>
  </button>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { displayPrice } from '../helper/currency';
import { useCartStore } from '../stores/cart';

const cartStore = useCartStore()
onMounted(async () => {
  await cartStore.init()
})
</script>
