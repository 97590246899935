<template>
  <div class="o-filter-popup" ref="popup">
    <div class="o-filter-popup__head">
      <h2 class="a-h3 m-filter__head-title">{{ translate('collection.filter.title') }}</h2>
      <button class="m-filter__head-close" @click="close" type="button">
        <svg class="a-icon" aria-hidden="true">
          <use href="#close" />
        </svg>
      </button>
    </div>
    <div class="o-filter-popup__actives" v-if="$slots.activeFilters">
      <div class="m-filter__active">
        <span class="m-product__filter-title">
          {{ translate('collection.filter.chosen_filters') }}
          <a class="o-filter-popup__reset" @click.prevent="collectionFilterStore.resetFilter(); close()">
            {{ translate('collection.filter.button_reset_filters') }}
          </a>
        </span>
        <ul class="m-filter__list" aria-label="Active filters">
          <slot name="activeFilters"></slot>
        </ul>
      </div>
    </div>
    <div class="o-filter-popup__filters">
      <slot name="filters"></slot>
    </div>

    <div class="o-filter-popup__buttons">
      <button class="a-btn a-btn--filter" type="button" @click="applyFilters()" v-if="collectionFilterStore.isChanged">
        <span class="a-btn__label"> {{ translate('collection.filter.button_apply') }}
        </span>
      </button>
      <button class="a-btn a-btn--filter" type="button" @click="close" v-if="!collectionFilterStore.isChanged">
        <span class="a-btn__label">{{ translate('collection.filter.button_close') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createFocusTrap } from 'focus-trap'
import { ComponentPublicInstance, nextTick, onMounted, ref } from 'vue'
import { useCollectionFilters } from '../../stores/collection'
import { translate } from '../../helper-functions'

const collectionFilterStore = useCollectionFilters()
const focusTrap = ref<any>(null)
const popup = ref<ComponentPublicInstance<HTMLDivElement>>()

onMounted(() => {
  nextTick(() => {
    focusTrap.value = createFocusTrap([popup.value as HTMLDivElement]).activate()
    window.addEventListener('keydown', (e: KeyboardEvent) => keyDownHandler(e), true)
  })
})

function close() {
  focusTrap.value.deactivate()
  collectionFilterStore.toggleMobileFilters()
}

function applyFilters() {
  let url = collectionFilterStore.getUrlForFilters()
  if (url === null) {
    close();
    return
  }

  window.location.href = url
}

function keyDownHandler(e: KeyboardEvent) {
  e.key === 'Esc' || e.key === 'Escape' ? close() : null
}
</script>
