<template>
  <fieldset>
    <button class="m-product__filter-title" @click="toggleFilters()" type="button">
      {{ name }}
      <svg class="a-icon a-icon--collapse" :class="{ 'reverse': filtersOpen }" aria-hidden="true">
        <use href="#chevron-down" />
      </svg>
    </button>
    <legend v-text="`Kies een ${name} om op te filteren`"></legend>
    <div class="m-product__filter-checkboxes" :class="{ 'open': filtersOpen }" :style="`--filter-columns: ${columns}`">
      <div class="a-checkbox" :class="{ 'a-checkbox--color': colors }"
        :style="colors ? `--color: ${filterOption?.data}` : ''" v-for="filterOption in props.options"
        :key="filterOption.id">
        <input class="a-checkbox__input" type="checkbox" :checked="filterOption.active"
          @change="toggleOption(filterOption.id)" :id="`${id}_${filterOption.id}`" :name="filterOption.name" />
        <label :for="`${id}_${filterOption.id}`" class="a-checkbox__box">
          <svg class="a-icon" aria-hidden="true"
            :class="{ 'light': colors && isLightColor(filterOption?.data, filterOption.name) }">
            <use href="#check" />
          </svg>
        </label>
        <label class="a-checkbox__text" :for="`${id}_${filterOption.id}`" v-text="filterOption.name"></label>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { Ref, ref } from 'vue'
import { RawFilterOption, useCollectionFilters } from '../../stores/collection'

const collectionFiltersStore = useCollectionFilters()
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  internalName: {
    type: String,
    required: true,
  },
  options: Array<RawFilterOption>,
  columns: Number,
  colors: Boolean,
  mobile: {
    type: Boolean,
    default: false,
  },
})

const id = ref('fieldset_' + uuidv4())
const filtersOpen: Ref<Boolean> = ref(true)

function toggleFilters() {
  filtersOpen.value = !filtersOpen.value
}

function toggleOption(id: string) {
  collectionFiltersStore.toggleFilterOption(props.internalName, id);
  if (props.mobile === true) {
    // Dont apply filters instantly on mobile wait until they are applied.
    return;
  }

  let url = collectionFiltersStore.getUrlForFilters()
  if (url === null) {
    return
  }

  window.location.href = url
}

function isLightColor(color: string, name: string = ''): boolean {
  try {
    if (!color.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i)) {
      return false;
    }

    if (color.match(/^#([0-9a-f]{3}$)/i)) {
      color = expandHexColor(color)
    }

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (result === null) {
      return false;
    }
    let r = parseInt(result[1], 16)
    let g = parseInt(result[2], 16)
    let b = parseInt(result[3], 16)

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    // This returns true if the color is light enough to use a black checkmark.
    return luma > 190
  } catch (e: any) {
    return false;
  }
}

function expandHexColor(hexCode: string): string {
  const hexValue = hexCode.substring(1);

  // If 3-digits, duplicate each digit.
  if (hexValue.length === 3) {
    const expandedHexValue = [...hexValue].map(x => `${x}${x}`).join('')
    return `#${expandedHexValue}`;
  }

  // Otherwise, we'll assume it's a 6-digit code and return the original.
  return hexCode;
}
</script>
