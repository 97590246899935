<template>
  <a :href="props.product.url" @click.capture="$event => productClickEvent($event, props.product!)"
    class="m-product__card" :aria-label="props.product.displayName" v-if="!loading && props.product">
    <div class="m-product__card-img">
      <picture class="a-image a-image--contain" style="--padding-ratio: calc(1/1)">
        <img class="a-image__img" v-if="(props.product.media ?? []).length > 0"
          :src="props.product.media[0].thumbUrl + '?height=5&width=5'"
          :data-src="props.product.media[0].thumbUrl + '?height=346&width=346'"
          :data-srcset="props.product.media[0].thumbUrl + '?height=346&width=346 1x , ' + props.product.media[0].thumbUrl + '?height=692&width=692 2x'"
          :alt="props.product.media[0].alt" loading="lazy" />
      </picture>
      <picture class="a-image a-image--contain">
        <img class="a-image__img" v-if="props.product.media.length === 1"
          :src="props.product.media[0].thumbUrl + '?height=5&width=5'"
          :data-src="props.product.media[0].thumbUrl + '?height=346&width=346'"
          :data-srcset="props.product.media[0].thumbUrl + '?height=346&width=346 1x , ' + props.product.media[0].thumbUrl + '?height=692&width=692 2x'"
          :alt="props.product.media[0].alt" loading="lazy" />
        <img class="a-image__img" v-if="props.product.media.length > 1"
          :src="props.product.media[1].thumbUrl + '?height=5&width=5'"
          :data-src="props.product.media[1].thumbUrl + '?height=346&width=346'"
          :data-srcset="props.product.media[1].thumbUrl + '?height=346&width=346 1x , ' + props.product.media[1].thumbUrl + '?height=692&width=692 2x'"
          :alt="props.product.media[1].alt" loading="lazy" />
      </picture>
      <div class="m-product__card-tags">
        <div class="m-product__card-tag m-product__card-tag--sale-percentage"
          v-if="props.displayDiscountPercentage && hasSale(props.product)">
          <span>
            -{{ discountPercentage(props.product.price, props.product.originalPrice) }}%
          </span>
        </div>
        <div v-else-if="hasSale(props.product)" class="m-product__card-tag m-product__card-tag--red"
          v-if="hasSale(props.product)">
          <span>{{ translate('product.tags.sale') }}</span>
        </div>
        <div class="m-product__card-tag m-product__card-tag--black" v-if="props.product.tags.includes('new')">
          <span>{{
            translate('product.tags.new') }}</span>
        </div>
      </div>
    </div>
    <div class="m-product__card-info">
      <div class="m-product__card-title" v-text="props.product.displayName"></div>
      <div class="m-product__card-prices">
        <div class="m-product__card-price m-product__card-price--sale" v-if="hasSale(props.product)">
          {{ translate('product.price.sale_from', { price: displayPrice(props.product.price) }) }}
        </div>
        <div class="m-product__card-price m-product__card-price--old" v-if="hasSale(props.product)"
          v-text="`${displayPrice(props.product.originalPrice)}`">
        </div>
        <div class="m-product__card-price" v-if="!hasSale(props.product)" v-text="`${displayPrice(props.product.price)}`">
        </div>
      </div>
    </div>
    <div class="m-product__card-variants" v-if="props.product.variants">
      <ul class="m-product__card-variant-list" aria-label="Varianten">
        <li class="m-product__card-variant">
          {{ formatConfigurableAttributes(props.product) }}
        </li>
      </ul>
    </div>
  </a>

  <div class="m-product__card m-product__card--loading" aria-label="" v-if="loading">
    <div class="m-product__card-img">
      <picture class="a-image" style="--padding-ratio: calc(1/1)">
      </picture>
    </div>
    <div class="m-product__card-info">
      <span class="m-product__card-title"></span>
      <div class="m-product__card-prices"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'
import { ProductData, hasSale } from '../models/product';
import { displayPrice, discountPercentage } from '../helper/currency';
import { translate } from '../helper-functions';
import { EventProductClick, emitEvent } from '../helper/tracking-events';
import { sortAttributeValueList } from '../helper/product';

const props = defineProps({
  product: {
    type: Object as () => ProductData,
  },
  configurableAttributes: {
    type: Array<string>,
    default: () => ['sc_size'],
  },
  displayDiscountPercentage: {
    type: Boolean,
    default: false,
  },
})

const loading: Ref<boolean> = ref(true)

function formatConfigurableAttributes(product: ProductData) {
  const attributes: string[] = []
  for (let variant of product.variants) {
    if (!variant.available) {
      continue
    }

    for (let internalName of props.configurableAttributes) {
      if (!Object.keys(variant.attributes ?? {}).includes(internalName)) {
        continue
      }

      attributes.push(variant.attributes[internalName].value)
    }
  }

  return sortAttributeValueList(attributes).join(' - ')
}

onMounted(async () => {
  if (props.product) {
    loading.value = false
  }
})

function productClickEvent(event: Event, product: ProductData) {
  let attributes: { [key: string]: string } = {}
  for (let [key, attribute] of Object.entries(product.attributes)) {
    attributes[key] = attribute.value
  }

  emitEvent(EventProductClick, {
    ID: product.ID,
    name: product.name,
    variants: product.variants.map(p => {
      let variantAttributes: { [key: string]: string } = {}
      for (let [key, attribute] of Object.entries(product.attributes)) {
        variantAttributes[key] = attribute.value
      }

      return {
        ID: p.ID,
        name: p.name,
        price: p.price,
        attributes: variantAttributes,
      }
    }),
    price: product.price,
    attributes: attributes,
  })
}
</script>
