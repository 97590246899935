<template>
  <div class="m-product__swiper">
    <div ref="slider" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide--product" v-for="product in props.products" :key="product.ID">
          <product-card :product="product" :display-discount-percentage="props.displayDiscountPercentage"></product-card>
        </div>
      </div>
    </div>
    <button type="button" ref="navPrev" class="a-btn a-btn--icon m-product__swiper-nav m-product__swiper-nav--left">
      <svg class="a-icon a-icon--navigation" aria-hidden="true">
        <use xlink:href="#navigation-left" />
      </svg>
    </button>
    <button type="button" ref="navNext" class="a-btn a-btn--icon m-product__swiper-nav m-product__swiper-nav--right">
      <svg class="a-icon a-icon--navigation" aria-hidden="true">
        <use xlink:href="#navigation-right" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import Swiper, { A11y, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { nextTick, onMounted, ref } from 'vue'
import { ProductData } from '../models/product'
import ProductCard from './ProductCard.vue'

const swiper = ref<Swiper>()

const props = defineProps({
  products: {
    type: Object as () => ProductData[],
    default: [],
  },
  displayDiscountPercentage: {
    type: Boolean,
    default: false,
  },
})

const slider = ref<HTMLDivElement>()
const navNext = ref<HTMLDivElement>()
const navPrev = ref<HTMLDivElement>()

onMounted(() => {
  nextTick(() => {
    swiper.value = new Swiper(slider.value!, {
      modules: [Navigation, A11y],
      navigation: {
        nextEl: navNext.value,
        prevEl: navPrev.value,
      },
      a11y: {
        enabled: true,
        containerMessage: 'Hero slider',
      },
      grabCursor: true,
      slidesPerView: 2.25,
      spaceBetween: 16,
      breakpoints: {
        480: {
          slidesPerView: 3.5,
        },
        1024: {
          slidesPerView: 4.5,
        },
      },
    })
  })
})


</script>
