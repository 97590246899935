<template>
  <div class="m-hero-slide__slider">
    <div ref="slider" class="swiper">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>
      <div class="m-hero-slide__navigation u-visible@md">
        <button v-if="props.enableNavigationButtons" type="button" ref="navPrev"
          class="a-btn a-btn--icon m-hero-slide__nav m-hero-slide__nav--left">
          <svg class="a-icon a-icon--navigation" aria-hidden="true">
            <use xlink:href="#navigation-left" />
          </svg>
        </button>
        <button v-if="props.enableNavigationButtons" type="button" ref="navNext"
          class="a-btn a-btn--icon m-hero-slide__nav m-hero-slide__nav--right">
          <svg class="a-icon a-icon--navigation" aria-hidden="true">
            <use xlink:href="#navigation-right" />
          </svg>
        </button>
      </div>
    </div>
    <div class="swiper-pagination" ref="pagination"></div>
  </div>
</template>

<script setup lang="ts">
import Swiper, { A11y, Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { ComponentPublicInstance, nextTick, onMounted, ref } from 'vue'

const swiper = ref<Swiper>()
const pagination = ref<ComponentPublicInstance<HTMLDivElement>>()
const slider = ref<ComponentPublicInstance<HTMLDivElement>>()
const navNext = ref<ComponentPublicInstance<HTMLDivElement>>()
const navPrev = ref<ComponentPublicInstance<HTMLDivElement>>()

const props = defineProps({
  enableNavigationButtons: {
    type: Boolean,
    default: true,
  }
})

onMounted(() => {
  nextTick(() => {
    swiper.value = new Swiper(slider.value as HTMLDivElement, {
      modules: [Pagination, Navigation, A11y, Autoplay],
      pagination: {
        el: pagination.value,
        type: 'bullets',
      },
      navigation: {
        nextEl: navNext.value as HTMLDivElement,
        prevEl: navPrev.value as HTMLDivElement,
      },
      autoplay: {
        delay: 10000,
      },
      a11y: {
        enabled: true,
        containerMessage: 'Hero slider',
      },
      grabCursor: true,
      loop: true,
    })
  })
})
</script>
