<template>
  <div class="m-collapse" :class="{ 'is-open': isOpen }" ref="collapse">
    <button type="button" class="m-collapse__title" :aria-expanded="isOpen ? 'true' : 'false'" :aria-controls="id" @click="toggle">
      <slot name="title"></slot>
      <span class="m-collapse__icon">
        <span></span>
        <span></span>
      </span>
    </button>

    <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @before-leave="beforeLeave" @leave="leave" @after-leave="afterLeave">
      <div class="m-collapse__content" v-show="isOpen" :aria-expanded="isOpen ? 'true' : 'false'" :aria-hidden="!isOpen ? 'true' : 'false'" :id="id">
        <div class="m-collapse__inner" ref="collapseInner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { ComponentPublicInstance, inject, onMounted, ref, watch } from 'vue'

const props = defineProps({
  opened: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref(false)
const id = ref('collapse_' + uuidv4())

const collapse = ref<ComponentPublicInstance<HTMLDivElement>>()
const collapseInner = ref<ComponentPublicInstance<HTMLDivElement>>()

const { openElement, setOpenElement } = inject<any>('element')

onMounted(() => {
  isOpen.value = props.opened
})

watch(openElement, (newValue: string) => {
  newValue === id.value ? isOpen.value = true : isOpen.value = false
})

const toggle = () => { openElement.value === id.value ? setOpenElement(null) : setOpenElement(id.value) }

const beforeEnter = (el: any) => {
  requestAnimationFrame(() => {
    if (!el.style.height) {
      el.style.height = '0px'
    }

    el.style.display = null
  })
}

const enter = (el: any) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      el.style.height = `${el.scrollHeight}px`
    })
  })
}

const afterEnter = (el: any) => {
  el.style.height = null
}

const beforeLeave = (el: any) => {
  requestAnimationFrame(() => {
    if (!el.style.height) {
      el.style.height = `${el.offsetHeight}px`
    }
  })
}

const leave = (el: any) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      el.style.height = '0px'
    })
  })
}

const afterLeave = (el: any) => {
  el.style.height = null
}
</script>
