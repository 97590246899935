import { defineStore } from "pinia";
import { ref, Ref } from "vue";

export interface MenuItem {
    name: string;
    url: string;
    active: boolean;
    items: Array<MenuItem>;
    tags: Array<string>;
}

export const useMenuStore = defineStore('menu', () => {
    let mobileMenuOpened: Ref<boolean> = ref(false);

    function toggleMobileMenu() {
        mobileMenuOpened.value = !mobileMenuOpened.value
    }

    function isMenuItemActive(url: string): boolean {
        let currentUrl = new URL(window.location.href)
        currentUrl.search = ''
        let path = ''
        for (let pathPart of currentUrl.pathname.split('/').filter(part => part !== '')){
            path += '/' + pathPart
            if (path === url) {
                return true;
            }
        }
        return false;
    }

    return {
        // State
        mobileMenuOpened,

        // Actions
        toggleMobileMenu,
        isMenuItemActive,
    }
})