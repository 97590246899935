<template>
  <footer>
    <div class="o-footer">
      <div class="o-container">
        <div class="o-footer__center">
          <div class="o-footer__main a-layout">
            <div class="o-footer__item u-col-span:2@md">
              <h2 class="o-footer__item-title u-screen-reader-only" id="footer-title-1">Footer</h2>
              <nav v-if="props.footerMenu.length > 0" class="o-footer__nav">
                <ul aria-labelledby="footer-title-1">
                  <li v-for="menu of props.footerMenu"><a :href="menu.url">{{ menu.name }}</a></li>
                </ul>
              </nav>
            </div>
            <div
              v-if="(props.textBlocks ?? []).length > 0"
              v-for="(block, index) in props.textBlocks"
              :class="`u-visible@md o-footer__item u-col-span:${index == 0 ? '4' : '3'}@md`"
            >
              <h3 class="o-footer__item-title">{{ block.title }}</h3>
              <div class="o-editor-content o-editor-content--footer" v-html="block.text"></div>
            </div>
            <div
              class="o-footer__item u-col-span:3@md"
              v-if="props.storeAddress !== null"
            >
              <h3 class="o-footer__item-title">{{ translate('contact.general.title') }}</h3>
              <div class="o-editor-content o-editor-content--footer">
                {{ props.storeAddress.street }} {{ props.storeAddress.house_number }}{{
                  props.storeAddress.house_number_extension ? ' ' +
                props.storeAddress.house_number_extension : '' }}<br>
                {{ props.storeAddress.zipcode }} {{ props.storeAddress.city }}<br>
                <a :href="`mailto:${props.storeAddress.email}`" :title="props.storeAddress.email">{{
                  props.storeAddress.email }}</a> <br>
                <a :href="`tel:${props.storeAddress.phone}`" :title="props.storeAddress.phone">
                  {{ props.storeAddress.phone }}</a><br><br>
                <div v-html="props.storeAddress.note">
                </div>
                <section
                  class="m-contact__opening-hours"
                  v-if="props.storeAddress.opening_hours.length > 0"
                >
                  <strong>
                    {{ translate('contact.opening_hours.title') }}
                  </strong>
                  <table>
                    <tbody>
                      <tr v-for="openingHour of props.storeAddress.opening_hours">
                        <td>{{ openingHour.label }}</td>
                        <td>{{ openingHour.value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <base-collapse-group class="u-col-span:12 u-hidden@md">
              <base-collapse
                v-if="(props.textBlocks ?? []).length > 0"
                v-for="block of props.textBlocks"
              >
                <template v-slot:title>
                  <h3 class="m-collapse__title m-collapse__title--footer">{{ block.title }}</h3>
                </template>
                <div class="o-editor-content o-editor-content--footer" v-html="block.text"></div>
              </base-collapse>
            </base-collapse-group>
          </div>
          <div class="o-footer__line"></div>
          <div class="o-footer__main a-layout o-footer__main--under-line">
            <div class="o-footer__item u-col-span:2@md">
              <h3 class="o-footer__bottom-item-title" id="footer-title-2">{{
                translate('global.social.follow_us') }}</h3>
              <ul class="o-footer__socials" aria-labelledby="footer-title-2">
                <li v-for="social of socialMedia">
                  <a
                    :href="social.url" class="o-footer__social-link"
                    :title="social.type.toLowerCase()"
                  >
                    <svg class="a-icon" aria-hidden="true">
                      <use :href="'#' + social.type.toLowerCase()" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div class="o-footer__item u-col-span:10@md">
              <span class="a-h2 o-footer__bottom-item-title">© {{ translate('global.shop.title') }}</span>
              <ul v-if="props.serviceMenu" class="o-footer__item-sub-menu">
                <li v-for="item of props.serviceMenu"><a :href="item.url">{{ item.name }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="o-footer">
      <div class="o-footer__bottom">
        <ul class="o-footer__bottom-list" v-if="paymentIcons && paymentIcons.length > 0">
          <li v-for="icon of paymentIcons">
            <picture class="a-image a-image--contain">
              <img
                v-if="icon === 'Riverty'"
                class="a-image__img"
                src="../assets/img/payment-logos/riverty.png"
                alt="Riverty"
                loading="lazy"
              >
              <img
                v-if="icon === 'Bancontact'"
                class="a-image__img"
                src="../assets/img/payment-logos/bancontact.png"
                alt="Bancontact"
                loading="lazy"
              >
              <img
                v-if="icon === 'iDeal'"
                class="a-image__img"
                src="../assets/img/payment-logos/ideal.png"
                alt="Ideal" loading="lazy"
              >
              <img
                v-if="icon === 'Maestro'"
                class="a-image__img"
                src="../assets/img/payment-logos/maestro.png"
                alt="Maestro" loading="lazy"
              >
              <img
                v-if="icon === 'Mastercard'"
                class="a-image__img"
                src="../assets/img/payment-logos/mastercard.png"
                alt="Mastercard"
                loading="lazy"
              >
              <img
                v-if="icon === 'Paypal'"
                class="a-image__img"
                src="../assets/img/payment-logos/paypal.png"
                alt="Paypal" loading="lazy"
              >
              <img
                v-if="icon === 'Visa'"
                class="a-image__img"
                src="../assets/img/payment-logos/visa.png"
                alt="Visa" loading="lazy"
              >
              <img
                v-if="icon === 'Sofort'"
                class="a-image__img"
                src="../assets/img/payment-logos/sofort.png"
                alt="Sofort" loading="lazy"
              >
              <img
                v-if="icon === 'Giropay'"
                class="a-image__img"
                src="../assets/img/payment-logos/giropay.png"
                alt="Giropay" loading="lazy"
              >
            </picture>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { PropType, nextTick, onMounted } from 'vue'
import BaseCollapse from './BaseCollapse.vue'
import BaseCollapseGroup from './BaseCollapseGroup.vue'
import { translate } from '../helper-functions'
import { lazyLoadUpdate } from '../lazyload'
import { StoreAddress } from '../helper/address'

const props = defineProps({
  footerMenu: {
    type: Array<{ name: string; url: string; }>,
    default: [],
  },
  serviceMenu: {
    type: Array<{ name: string; url: string; }>,
    default: [],
  },
  textBlocks: {
    type: Array<{ title: string; text: string; }>,
    default: [],
  },
  socialMedia: {
    type: Array<{
      type: string;
      url: string;
    }>,
    default: [],
  },
  paymentIcons: {
    type: Array<string>,
    default: null,
  },
  storeAddress: {
    type: Object as PropType<StoreAddress|null>,
    default: null,
  }
})

onMounted(() => {
  nextTick(() => {
    lazyLoadUpdate()
  })
})
</script>
