<template>
  <div class="m-drilldown">
    <button class="m-drilldown__btn" type="button" @click="open">
      <span v-text="label"></span>
      <svg class="m-drilldown__arrow" aria-hidden="true">
        <use xlink:href="#chevron-right" />
      </svg>
    </button>
  </div>
  <transition>
    <div class="m-drilldown__content" ref="content" v-if="active">
      <button class="m-drilldown__btn m-drilldown__btn--heading" type="button" @click="close">
        <svg class="m-drilldown__arrow m-drilldown__arrow--left" aria-hidden="true">
          <use xlink:href="#chevron-left" />
        </svg>
        <span v-text="props.label"></span>
      </button>
      <slot></slot>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { createFocusTrap } from "focus-trap"
import {nextTick, onBeforeUnmount, ref} from "vue"

const props = defineProps({ label: String })
const active = ref(false)
const focusTrap = ref<any>()
const content = ref<HTMLDivElement>()
const toggle = ref<HTMLDivElement>()

const open = () => {
  active.value = true

  nextTick(() => {
    const elements: Array<HTMLElement> = [content.value as HTMLDivElement, toggle.value as HTMLDivElement]

    const menuCloseBtn = document.getElementById('mobile-menu-close-btn')
    if (menuCloseBtn) {
      elements.push(menuCloseBtn)
    }

    focusTrap.value = createFocusTrap(elements).activate()
    window.addEventListener('keydown', (e: KeyboardEvent) => keyDownHandler(e), true)
  })
}

onBeforeUnmount(() => {
  if (focusTrap.value) {
    focusTrap.value.deactivate()
  }
})

const close = () => {
  active.value = false
  focusTrap.value.deactivate()
}

const keyDownHandler = (e: KeyboardEvent) => {
  e.key === 'Esc' || e.key === 'Escape' ? close() : null
}
</script>
