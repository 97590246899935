import { defineStore } from "pinia"
import { ref, Ref } from "vue"

export const useRecaptchaStore = defineStore('recaptcha', () => {
    const isLoaded: Ref<Boolean> = ref(false)
    const recaptchaSiteKey: Ref<string> = ref('')
    function init(siteKey: string) {
        if (isLoaded.value) {
            return
        }

        recaptchaSiteKey.value = siteKey
        var script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${encodeURIComponent(recaptchaSiteKey.value)}`
        document.head.appendChild(script)
        isLoaded.value = true;
    }
    async function isReady(): Promise<boolean> {
        if (!isLoaded.value) {
            return false
        }
        await (window as any).grecaptcha.ready(() => { })
        return true
    }

    async function getRecaptchaToken(actionName: string = 'submit'): Promise<string> {
        const ready = await isReady()
        if (!ready) {
            return ''
        }

        return (window as any).grecaptcha.execute(recaptchaSiteKey.value, { action: actionName })
    }

    return {
        init,
        getRecaptchaToken
    }
})