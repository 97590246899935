<template >
  <transition>
    <div class="m-mobile-menu__wrapper" v-if="menuStore.mobileMenuOpened">
      <div class="m-mobile-menu" ref="mobileMenu">
        <button type="button" id="mobile-menu-close-btn" class="m-mobile-menu__close a-btn a-btn--icon" aria-label="Sluiten"
          @click="menuStore.toggleMobileMenu()">
          <svg class="a-icon a-icon--close" aria-hidden="true">
            <use href="#close" />
          </svg>
        </button>

        <div class="m-mobile-menu__head">
          <h2 class="m-mobile-menu__head-title a-h5">{{ translate('menu.mobile.title') }}</h2>
        </div>

        <ul class="m-mobile-menu__list" aria-label="Menu">
          <li v-for="item of props.mainMenuItems" class="m-mobile-menu__list-item"
            :class="{ 'm-mobile-menu__list-item--sale': (item?.tags ?? []).includes('sale'), 'm-mobile-menu__list-item--new': (item?.tags ?? []).includes('new') }">
            <BaseDrilldown v-if="item.items && item.items.length > 0" :label="item.name">
              <ul class="m-mobile-menu__list" aria-label="Collections">
                <li class="m-mobile-menu__list-item" v-if="!(item?.tags ?? []).includes('brands')">
                  <a :href="item.url" class="m-mobile-menu__list-link">{{
                    translate('collection.products.show_all_products', {
                      collection: item.name
                    }) }}</a>
                </li>
                <template v-if="hasGroup(item)">
                  <template v-for="child of item.items">
                    <li v-for="grandChild of leafNodes(child)" class="m-mobile-menu__list-item">
                      <a :href="grandChild.url" class="m-mobile-menu__list-link">{{ grandChild.name }}</a>
                    </li>
                    <li class="m-mobile-menu__seperator"></li>
                  </template>
                </template>
                <template v-else>
                  <li v-for="child of leafNodes(item)" class="m-mobile-menu__list-item">
                    <a :href="child.url" class="m-mobile-menu__list-link">{{ child.name }}</a>
                  </li>
                </template>
              </ul>

            </BaseDrilldown>
            <a v-else :href="item.url" class="m-mobile-menu__list-link">{{ item.name }}</a>
          </li>

          <li class="m-mobile-menu__seperator"></li>

          <li class="m-mobile-menu__list-item" v-if="props.topMenuItems && props.topMenuItems.length > 0"
            v-for="item of props.topMenuItems">
            <BaseDrilldown v-if="item.items && item.items.length > 0" :label="item.name">
              <ul class="m-mobile-menu__list" aria-label="Menu items">
                <li class="m-mobile-menu__list-item">
                  <a :href="item.url" class="m-mobile-menu__list-link m-mobile-menu__list-link--current">
                    {{ translate('menu.mobile.visit', { title: item.name }) }}
                  </a>
                </li>
                <li v-for="child of item.items" class="m-mobile-menu__list-item">
                  <a :href="child.url" class="m-mobile-menu__list-link">
                    {{ child.name }}
                  </a>
                </li>
              </ul>
            </BaseDrilldown>
            <a v-else :href="item.url" class="m-mobile-menu__list-link">{{ item.name }}</a>
          </li>
        </ul>
        <div v-if="alternateURLs.length > 0" class="m-header-language-select">
          <base-language-switch :items="alternatedURLsAsMap"></base-language-switch>
        </div>
      </div>
      <div class="a-overlay"></div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { MenuItem, useMenuStore } from '../stores/menu';
import { translate } from '../helper-functions';
import BaseDrilldown from './BaseDrilldown.vue';
import { computed, onMounted, Ref, ref } from 'vue';

const menuStore = useMenuStore()

const props = defineProps({
  mainMenuItems: {
    type: Array<MenuItem>,
    default: [],
  },
  topMenuItems: {
    type: Array<MenuItem>,
    default: [],
  },
})

interface AlternateURL {
  locale: string;
  url: string;
}

const alternateURLs: Ref<Array<AlternateURL>> = ref([])
const alternatedURLsAsMap: Ref<Record<string, string>> = computed(() => {
  const map: Record<string, string> = {}
  alternateURLs.value.forEach((alternate) => {
    map[alternate.locale] = alternate.url
  })

  return map
})

onMounted(() => {
  alternateURLs.value = getAlternateURLs()
})

// Returns if the item has grandchildren. If so we render the group name names.
function hasGroup(item: MenuItem): boolean {
  return item.items?.some((group) => group.items?.length > 0) ?? false
}

// Items returns the menu items that should be displayed for a submenu.
// It only displays the leaf nodes. This means that the following menu:
// categorie
//    jassen
//    broeken
// merken
//    adidas
//    nike
//
// Will only display jassen, broeken, adidas and nike.
function leafNodes(item: MenuItem): Array<MenuItem> {
  if (item.items && item.items.length > 0) {
    return item.items.flatMap(leafNodes)
  }

  return [item]
}

function getAlternateURLs(): Array<AlternateURL> {
  const links = document.getElementsByTagName('link')

  const alternates = []
  for (let i = 0; i < links.length; i++) {
    if (links[i].rel === 'alternate') {
      const locale = links[i].hreflang
      const url = links[i].href
      alternates.push({ locale, url })
    }
  }

  return alternates
}
</script>
