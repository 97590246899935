<template>
  <button class="a-btn a-btn--filter" type="button" @click="collectionFiltersStore.toggleMobileFilters()">
    <span class="a-btn__label">Filters</span>
    <span class="a-btn__amount" v-if="props.amountOfActiveFilters" v-text="props.amountOfActiveFilters"></span>
  </button>
  <slot name="select"></slot>
</template>

<script setup lang="ts">
import { useCollectionFilters } from '../stores/collection';

let collectionFiltersStore = useCollectionFilters()
const props = defineProps({
  amountOfActiveFilters: {
    type: Number,
    required: true
  },
})
</script>
