<template >
  <button type="button" class="a-btn a-btn--icon" :title="translate('global.search.label')" @click="searchStore.toggleSearchPanel()">
    <svg class="a-icon a-icon--search" aria-hidden="true">
      <use href="#search" />
    </svg>
  </button>
</template>

<script setup lang="ts">
import { useSearchStore } from '../stores/search';
import { translate } from '../helper-functions';

const searchStore = useSearchStore();

</script>
