<template>
  <div class="m-brand-slide__slider" v-show="!loading">
    <div ref="slider" class="swiper">
      <div class="swiper-wrapper">
        <a v-for="brand of props.brands" :href="brand.url" class="m-brand-slide swiper-slide">
          <picture class="a-image a-image--contain" style="--padding-ratio: calc(100 / 200);">
            <img class="a-image__img" :src="brand.image" :alt="brand.alt" loading="lazy">
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper, { A11y, Autoplay, Lazy } from 'swiper'
import 'swiper/css'
import { ComponentPublicInstance, nextTick, onMounted, Ref, ref } from 'vue'
import { lazyLoadUpdate } from '../lazyload';

const swiper = ref<Swiper>()
const slider = ref<ComponentPublicInstance<HTMLDivElement>>()
const loading: Ref<boolean> = ref(true)

const props = defineProps({
  brands: Array<{ image: string; url: string; alt: string; }>
})

onMounted(() => {
  nextTick(() => {
    lazyLoadUpdate()
    swiper.value = new Swiper(slider.value as HTMLDivElement, {
      modules: [A11y, Lazy, Autoplay],
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 6.5,
      centeredSlides: true,
      grabCursor: true,
      loop: true,
      breakpoints: {
        0: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        480: {
          slidesPerView: 4.8,
        },
        1080: {
          slidesPerView: 6.5,
        }
      }
    })
  })
  loading.value = false
})
</script>
