<template>
  <transition>
    <div class="o-cart-popover" v-if="cartStore.isPopoverOpened" ref="popover">
      <div class="m-cart-popover__head">
        <h2 class="m-cart-popover__head-title a-h4">
          {{ t('cart.general.title') }}
        </h2>
        <button class="a-btn" type="button" :title="translate('cart.button.close')" @click="closePopup">
          <svg class="a-icon a-icon--close" aria-hidden="true">
            <use href="#close" />
          </svg>
        </button>
      </div>
      <div v-if="cartStore.cart.products.length > 0" class="o-cart-popover__inner">
        <transition-group name="cart-list" tag="ul" class="m-cart-popover__products"
          :aria-label="t('cart.general.title')">
          <li class="m-cart-popover__product" v-for="product in cartStore.cart.products" :key="product.ID">
            <div class="m-cart-popover__product-top">
              <div class="m-cart-popover__product-img">
                <!-- @TODO Fix images with correct sources padding-ratio alt and stuff -->
                <picture class="a-image a-image--contain" style="--padding-ratio: calc(1/1)">
                  <img class="a-image__img" :src="product.image_url + '?width=60&height=60'" :alt="product.name" />
                </picture>
              </div>
              <div class="m-cart-popover__product-info">
                <a href="#" class="m-cart-popover__product-title">
                  {{ product.name }}
                </a>
                <span class="m-cart-popover__product-attribute">
                  <strong>
                    {{ product.quantity + 'x' }}
                  </strong>
                </span>
                <span class="m-cart-popover__product-attribute"
                  v-for="[name, attributeValue] of Object.entries(product.attributes)">
                  {{ attributeValue.name }}: {{ attributeValue.value }}
                </span>
              </div>
              <div class="m-cart-popover__product-price">
                <span class="m-cart-popover__price--original" v-if="product.price < product.original_price">
                  {{ displayPrice(product.original_price)}}
                </span>
                <span :class="{'m-cart-popover__price--discount': product.price < product.original_price}">
                  {{ displayPrice(product.price) }}
                </span>
              </div>
            </div>
            <div class="m-cart-popover__product-actions">
              <a :href="generateRoute('cart')" class="m-cart-popover__product-action m-cart-popover__product-action-edit"
                :title="t('cart.button.edit')" :aria-label="t('cart.button.edit')">
                <svg class="a-icon a-icon--cart-filter" aria-hidden="true">
                  <use href="#edit" />
                </svg>
              </a>
            </div>
          </li>
        </transition-group>
        <div class="m-cart-popover__prices">
          <div class="m-cart-popover__price" v-if="cartStore.cart.checkout_method">
            <span class="m-cart-popover__price--label">{{ cartStore.cart.checkout_method!.name }}</span>
            <span class="m-cart-popover__price--shipping"
              :class="{ 'm-cart-popover__price--free': (cartStore.cart.checkout_method?.price ?? 0) === 0 }">
              {{ displayPrice(cartStore.cart.checkout_method!.price ?? 0) }}
            </span>
          </div>
          <div class="m-cart-popover__price" v-if="cartStore.cart.coupon">
            <span class="m-cart-popover__price--label">
              {{ t('cart.general.coupon_price.label', { code: cartStore.cart.coupon!.code }) }}</span>
            <span class="m-cart-popover__price--coupon m-cart-popover__price--discount">
              {{ displayPrice((cartStore.cart.coupon!.discount ?? 0) * -1) }}
            </span>
          </div>
          <div class="m-cart-popover__price">
            <span class="m-cart-popover__price--label">
              {{ t('cart.general.price_total') }}
            </span>
            <span class="m-cart-popover__price--total">
              {{ currencyDisplayPrice(cartStore.cart.total) }}
            </span>
          </div>
        </div>
        <div class="m-cart-popover__action">
          <a :href="generateRoute('cart')" class="a-btn a-btn--add">
            {{ t('cart.button.checkout') }}
          </a>
        </div>
      </div>
      <div v-else-if="!cartStore.loadingCart" class="a-notification a-notification--info">
        {{ translate('cart.empty.text') }}
      </div>
      <div v-else class="m-cart-popover__product m-cart-popover__product--skeleton">
        <div class="m-cart-popover__product-top">
          <div class="m-cart-popover__product-img"></div>
          <div class="m-cart-popover__product-info">
            <span class="m-cart-popover__product-title"></span>
            <span class="m-cart-popover__product-attribute"></span>
            <span class="m-cart-popover__product-attribute"></span>
          </div>
          <div class="m-cart-popover__product-price"></div>
        </div>
        <div class="m-cart-popover__product-actions"></div>
      </div>
    </div>
  </transition>
  <transition>
    <button v-if="cartStore.isPopoverOpened" class="a-overlay" aria-hidden="true" type="button" ref="overlay"
      @click="closePopup" />
  </transition>
</template>

<script setup lang="ts">

import { createFocusTrap } from 'focus-trap'
import { storeToRefs } from 'pinia'
import { ComponentPublicInstance, nextTick, onMounted, ref, TransitionGroup, watch } from 'vue'
import { useCartStore } from '../stores/cart'
import { displayPrice as currencyDisplayPrice } from '../helper/currency'
import { generateRoute, translate } from '../helper-functions'
import { useI18n } from 'vue-i18n'

const focusTrap = ref<any>(null)
const popover = ref<ComponentPublicInstance<HTMLDivElement>>()
const overlay = ref<ComponentPublicInstance<HTMLDivElement>>()
const cartStore = useCartStore()
const { isPopoverOpened } = storeToRefs(cartStore)
const { t } = useI18n()
const closePopup = () => {
  cartStore.closeCart()
  focusTrap.value.deactivate()
}

const keyDownHandler = (e: KeyboardEvent) => {
  e.key === 'Esc' || e.key === 'Escape' ? closePopup() : null
}

onMounted(async () => {
  await cartStore.init()
  // Start with a closed popover on loading of this component.
  cartStore.closeCart()
})

watch(isPopoverOpened, (changedIsOpen) => {
  if (changedIsOpen) {
    nextTick(() => {
      focusTrap.value = createFocusTrap([popover.value as HTMLDivElement, overlay.value as HTMLDivElement]).activate()
      window.addEventListener('keydown', (e: KeyboardEvent) => keyDownHandler(e), true)
    })
  }
})

function displayPrice(price: number): string {
  return currencyDisplayPrice(price ?? 0, translate('global.price.free'))
}
</script>
