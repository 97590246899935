<template>
  <div class="o-container" v-if="props.usps && props.usps.length > 0">
    <div ref="slider" class="m-header__usps--mobile u-hidden@lg">
      <div class="m-header__usps-wrapper swiper-wrapper">
        <div v-for="usp of props.usps" class="m-header__usp m-header__usp--mobile swiper-slide">
          <svg class="a-icon a-icon--usp">
            <use xlink:href="#check"></use>
          </svg>
          <span class="m-header__usps-text">{{ usp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper, { Autoplay } from 'swiper'
import 'swiper/css'
import { ComponentPublicInstance, nextTick, onMounted, ref } from 'vue'

const swiper = ref<Swiper>()
const slider = ref<ComponentPublicInstance<HTMLDivElement>>()

const props = defineProps({
  usps: {
    type: Array<string>,
    default: [],
  },
})

onMounted(() => {
  nextTick(() => {
    swiper.value = new Swiper(slider.value as HTMLDivElement, {
      modules: [Autoplay],
      loop: true,
      grabCursor: true,
      autoplay: {
        delay: 3000,
      },
    })
  })
})
</script>
