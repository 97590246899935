<template>
  <div class="o-container" v-if="props.product">
    <div class="o-product-sets__detail">
      <picture class="a-image" v-if="props.product.media && props.product.media.length > 0" style="--padding">
        <img class="a-image__img" :alt="props.product.media[0].alt" :title="props.product.media[0].title"
          :src="props.product.media[0].thumbUrl + '?width=5&height=5'"
          :data-src="props.product.media[0].thumbUrl + '?width=434&height=434'"
          loading="lazy" />
      </picture>
      <div class="m-product-set__info">
        <h3 class="m-product-info__title">{{ props.product.displayName }}</h3>
        <div class="m-product-info__cost">
          <span class="m-product-info__price m-product-info__price--sale"
            v-if="hasSale(props.product)">{{ displayPrice(props.product.price) }}</span>
          <span class="m-product-info__price m-product-info__price--old"
            v-if="hasSale(props.product)">{{ displayPrice(props.product.originalPrice)
            }}</span>
          <span class="m-product-info__price" v-else>{{ displayPrice(props.product.price) }}</span>
        </div>
        <form class="m-product-set__form" @submit.prevent="cartStore.add(selectedProduct.ID, 1, props.product!.ID !== selectedProduct.ID ? props.product!.ID : null)"
          v-if="props.product.variants.length > 0">
          <div class="m-products__sort">
            <select v-model="selectedProduct" class="a-select" id="id" name="sort">
              <template v-for="(option) in availableVariants" :key="option.ID">
                <option :value="option" :selected="selectedProduct.ID === option.ID"
                  v-text="formatConfigurableAttributes(option, props.configurableAttributes ?? [])"></option>
              </template>
            </select>
            <div class="a-select__icon">
              <svg class="a-icon a-icon--pagination" aria-hidden="true">
                <use href="#chevron-down" />
              </svg>
            </div>
          </div>
          <button class="a-btn a-btn--cart" type="submit">{{ t('cart.button.add-to-cart') }}</button>
        </form>
        <div class="m-product-set__form">
          <div class="m-products__sort">
            <button v-if="props.product.type === ProductType.Simple" class="a-btn a-btn--cart" type="button"
              @click="cartStore.add(selectedProduct.ID, 1, props.product!.ID !== selectedProduct.ID ? props.product!.ID : null)">{{ t('cart.button.add-to-cart') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, onMounted, ref } from 'vue'
import { ProductData, ProductType, Variant, hasSale } from '../../models/product';
import { formatConfigurableAttributes } from '../../helper/product';
import { useI18n } from 'vue-i18n';
import { useCartStore } from '../../stores/cart';
import { displayPrice } from '../../helper/currency';

const cartStore = useCartStore()
onMounted(async () => {
  await cartStore.init()
})

const { t } = useI18n()
const props = defineProps({
  product: {
    type: Object as () => ProductData,
  },
  configurableAttributes: {
    type: Array<string>,
    default: () => ['sc_size'],
  }
})

const loading = ref(true)
const availableVariants = computed(() => {
  return (props.product?.variants ?? []).filter(v => v.available)
})
const selectedProduct: Ref<Variant> = ref(props.product!)

function setInitialSelectedProduct() {
  if (availableVariants.value.length > 0) {
    if (window.location.hash !== '' && window.location.hash.substring(1).length > 0) {
      let variant = availableVariants.value.find((variant) => {
        if (variant.ID === window.location.hash.substring(1)) {
          return true
        }
      })
      if (variant) {
        selectedProduct.value = variant
        return
      }
    }

    selectedProduct.value = availableVariants.value[0]
  } else {
    selectedProduct.value= props.product!
  }
}

onMounted(async () => {
  if (props.product) {
    setInitialSelectedProduct()
    loading.value = false
  }
})
</script>
