<template>
  <div class="m-product-info">
    <h1 class="m-product-info__title">{{ productName }}</h1>
    <div class="m-product-info__cost" v-if="!hidePrice">
      <span
        v-if="selectedProductHasSale"
        class="m-product-info__price m-product-info__price--sale"
      >
        {{ selectedPrice }}
      </span>
      <span
        v-if="selectedProductHasSale"
        class="m-product-info__price m-product-info__price--old"
      >
        {{ selectedOriginalPrice }}
      </span>
      <span v-else class="m-product-info__price">
        {{ selectedPrice }}
      </span>
    </div>
    <div class="m-product-info__variants">
      <div
        v-for="[internalName, attributeValue] of Object.entries(props.product.attributes).filter(([internalName, av]) => props.displayedAttributes.includes(internalName))"
        class="m-product-info__variants-current"
        :key="internalName"
      >
        <span>{{ attributeValue.name }}:</span> {{ attributeValue.value }}
      </div>
      <ul class="m-product-info__variants-list" v-if="colorVariants.length > 0">
        <li class="m-product-info__variant active">
          <div class="m-product-info__variant-link">
            <picture
              v-if="props.product.media.length > 0"
              class="a-image a-image--contain"
              style="--padding-ratio: calc(1/1);"
            >
              <img class="a-image__img"
                :src="props.product.media[0].thumbUrl + '?widht=5&height=5'"
                :data-src="props.product.media[0].thumbUrl + '?widht=150&height=150'"
                :alt="props.product.media[0].alt" loading="lazy" />
            </picture>
          </div>
        </li>
        <li
          v-for="colorVariant of colorVariants"
          class="m-product-info__variant"
          :key="colorVariant.ID"
        >
          <a class="m-product-info__variant-link" :href="colorVariant.url">
            <picture
              v-if="colorVariant.media.length > 0"
              class="a-image a-image--contain"
              style="--padding-ratio: calc(1/1);"
            >
              <img
                class="a-image__img"
                :src="colorVariant.media[0].thumbUrl + '?widht=5&height=5'"
                :data-src="colorVariant.media[0].thumbUrl + '?widht=150&height=150'"
                :alt="colorVariant.media[0].alt" loading="lazy"
              >
            </picture>
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="!hidePrice && props.product.available"
      class="m-product-info__order"
    >
      <!-- <button class="a-btn m-product-info__size-chart" type="button">Maatbalk</button> -->
      <div class="m-products__sort" v-if="props.product.variants.filter(v => v.available).length > 0">
        <select
          v-model="selectedProduct"
          @change="$event => updateSelection(selectedProduct)"
          class="a-select"
          name="sort"
        >
          <template
            v-for="option in sortAttributeValueList(availableVariants, (variant: Variant): string => {
              return formatConfigurableAttributes(variant, props.configurableAttributes)
            })"
            :key="option.ID"
          >
            <option
              v-text="formatConfigurableAttributes(option, props.configurableAttributes)"
              :value="option"
              :selected="selectedProduct.ID === option.ID"
            >
            </option>
          </template>
        </select>
        <div class="a-select__icon">
          <svg class="a-icon a-icon--pagination" aria-hidden="true">
            <use href="#chevron-down" />
          </svg>
        </div>
      </div>

      <button class="a-btn a-btn--add" type="button" @click="$event => addToCart()">
        {{ t('cart.button.add-to-cart') }}
      </button>
    </div>
    <div v-if="!hidePrice &&
     !props.product.available">
      <p class="m-product-info__sold-out">
        {{ translate('product_detail.product_info.sold_out', {}) }}
      </p>
      <button class="a-btn a-btn--add" type="button" disabled>
        {{ t('cart.button.add-to-cart') }}
      </button>
    </div>
    <ul class="m-product-info__usps" v-if="props.usps.length">
      <li class="m-product-info__usp" v-for="usp in props.usps" :key="usp">
        <svg class="a-icon a-icon--usp">
          <use xlink:href="#check"></use>
        </svg>
        <span class="m-product-info__usp-text">{{ usp }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, Ref, computed, nextTick, onMounted, ref } from 'vue'
import { useCartStore } from '../../stores/cart'
import { displayPrice } from '../../helper/currency'
import { ProductData, Variant, hasSale, ProductType } from '../../models/product'
import { useI18n } from 'vue-i18n'
import { formatConfigurableAttributes, sortAttributeValueList } from '../../helper/product'
import { lazyLoadUpdate } from '../../lazyload'
import { translate } from '../../helper-functions'

const { t } = useI18n()
const cartStore = useCartStore()

const props = defineProps({
  product: {
    type: Object as () => ProductData,
    required: true,
  },
  colorVariants: {
    type: Array as () => ProductData[],
    required: true,
    default: () => [],
  },
  usps: {
    type: Array as () => string[],
    required: true,
  },
  configurableAttributes: {
    type: Array<string>,
    default: () => ['sc_size'],
  },
  displayedAttributes: {
    type: Array<string>,
    default: () => ['brand', 'sc_color', 'material'],
  }
})
const availableVariants = computed(() => {
  return props.product.variants.filter(v => v.available)
})
const selectedProduct: Ref<Variant> = ref(props.product)
const selectedProductHasSale: ComputedRef<boolean> = computed(() => {
  return hasSale(selectedProduct.value)
})
const selectedPrice:  ComputedRef<string> = computed(()=> displayPrice(selectedProduct.value.price))
const selectedOriginalPrice: ComputedRef<string> = computed(()=> displayPrice(selectedProduct.value.originalPrice))
const productName:  ComputedRef<string> = computed(()=> props.product.displayName)
const hidePrice: ComputedRef<boolean> = computed(() => {
  return props.product.tags.includes('hide-price')
})

onMounted(async () => {
  await cartStore.init()
  setInitialSelectedProduct()
  nextTick(() => {
    lazyLoadUpdate()
  })
})

function addToCart() {
  let configurationOf = null
  if (props.product.ID !== selectedProduct.value.ID) {
    configurationOf = props.product.ID
  }

  cartStore.add(selectedProduct.value.ID, 1, configurationOf)
}

function updateSelection(variant: Variant) {
  selectedProduct.value = variant
  window.location.hash = variant.ID
}

function setInitialSelectedProduct() {
  if (availableVariants.value.length > 0) {
    if (window.location.hash !== '' && window.location.hash.substring(1).length > 0) {
      let variant = availableVariants.value.find((variant) => {
        if (variant.ID === window.location.hash.substring(1)) {
          return true
        }
      })

      if (variant) {
        updateSelection(variant)
        return
      }
    }

    updateSelection(availableVariants.value[0])
  } else {
    updateSelection(props.product)
  }
}

</script>
