<template>
  <transition>
    <div class="m-search-bar" role="dialog" :aria-labelledby="translate('global.search.label')"
      :aria-describedby="translate('global.search.label')" v-if="searchStore.searchPanelOpened">
      <div class="o-container">
        <form class="m-search-bar__form" :action="searchURL" method="get">
          <input name="term" id="main_searchbar" type="text" class="m-search-bar__input"
            :placeholder="translate('global.search.placeholder')" ref="searchInput"
            data-searchbar-input="" v-model="searchStore.searchQuery">
          <button
            type="submit"
            class="m-search-bar__search a-btn a-btn--searchbar"
            :title="translate('global.search.label')"
          >
            <svg class="a-icon a-icon--search--search">
              <use xlink:href="#search"></use>
            </svg>
          </button>
          <button
            type="button"
            class="m-search-bar__close a-btn a-btn--searchbar"
            data-searchbar="searchbar"
            @click="searchStore.toggleSearchPanel();"
            title="Close"
          >
            <svg class="a-icon a-icon--close">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useSearchStore } from '../stores/search'
import { generateRoute, translate } from '../helper-functions'
import { Ref, nextTick, ref, watch, onMounted, computed } from 'vue'

const searchStore = useSearchStore()
const searchInput: Ref<HTMLElement | null> = ref(null)
const searchURL = computed(() => {
  if (typeof (window as any).searchRoute !== "undefined") {
    return (window as any).searchRoute
  }

  return generateRoute('search')
})

onMounted(() => {
  searchStore.init()
})


watch(() => searchStore.searchPanelOpened, (opened) => {
  if (opened) {
    nextTick(() => {
      searchInput.value?.focus()
    })
  }
})

</script>
