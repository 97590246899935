import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import 'virtual:svg-icons-register'
import { createApp } from 'vue'
import i18n from './i18n'
import 'what-input'
import './scss/main.scss'

import HeroSlider from './components/HeroSlider.vue'
import ProductHighlightSlider from './components/ProductHighlightSlider.vue'
import BrandSlider from './components/BrandSlider.vue'
import TheFooter from './components/TheFooter.vue'
import BaseBreadcrumbs from './components/BaseBreadcrumbs.vue'
import BaseNewsletter from './components/BaseNewsletter.vue'
import TopNotification from './components/TopNotification.vue'
import BaseLanguageSwitch from './components/BaseLanguageSwitch.vue'
import BaseSelect from './components/BaseSelect.vue'
import BaseActions from './components/BaseActions.vue'
import BaseFilters from './components/collection/BaseFilters.vue'
import BaseFiltersMobile from './components/collection/BaseFiltersMobile.vue'
import RecursiveCollectionList from './components/collection/RecursiveCollectionList.vue'
import ProductCard from './components/ProductCard.vue'
import BaseProductOverviewSortingOptions from './components/BaseProductOverviewSortingOptions.vue'
import BasePagination from './components/BasePagination.vue'
import BaseTopMenu from './components/BaseTopMenu.vue'
import BaseSocialIcons from './components/BaseSocialIcons.vue'
import BaseUsps from './components/BaseUsps.vue'
import BaseSearchButton from './components/BaseSearchButton.vue'
import BaseCartButton from './components/BaseCartButton.vue'
import BaseMobileUsps from './components/BaseMobileUsps.vue'
import BaseMainMenu from './components/BaseMainMenu.vue'
import BaseMobileMenu from './components/BaseMobileMenu.vue'
import BaseMobileMenuButton from './components/BaseMobileMenuButton.vue'
import BaseLogo from './components/BaseLogo.vue'
import BaseCartPopover from './components/BaseCartPopover.vue'
import BaseSearchBar from './components/BaseSearchBar.vue'
import ProductDetailSlider from './components/product-detail/ProductDetailSlider.vue'
import ProductDetailInfo from './components/product-detail/ProductDetailInfo.vue'
import ProductDetail from './components/product-detail/ProductDetail.vue'
import ArticlePreview from './components/blog/ArticlePreview.vue'
import BaseArticle from './components/blog/BaseArticle.vue'
import ArticleCallToActionBlock from './components/blog/ArticleCallToActionBlock.vue'
import ArticleImageTextBlock from './components/blog/ArticleImageTextBlock.vue'
import ArticleTitleTextBlock from './components/blog/ArticleTitleTextBlock.vue'
import BaseContact from './components/contact/BaseContact.vue'
import ShopTheLookProduct from './components/shop-the-look/ShopTheLookProduct.vue'
import CollectionOverviewEvent from './components/CollectionOverviewEvent.vue'
import BaseCookieBar from './components/BaseCookieBar.vue'

import BaseShipmentSelect from './components/checkout/BaseShipmentSelect.vue'
import BaseCheckout from './components/checkout/BaseCheckout.vue'
import BaseCart from './components/checkout/BaseCart.vue'

const pinia = createPinia()

const app = createApp({}, {})

app.use(pinia)
app.use(Notifications)
app.use(i18n)

// Cart page components.
app.component('base-shipment-select', BaseShipmentSelect)
app.component('base-checkout', BaseCheckout)
app.component('base-cart', BaseCart)

app.component('base-notificatons', Notifications)
app.component('hero-slider', HeroSlider)
app.component('product-highlight-slider', ProductHighlightSlider)
app.component('brand-slider', BrandSlider)
app.component('the-footer', TheFooter)

app.component('base-breadcrumbs', BaseBreadcrumbs)
app.component('base-newsletter', BaseNewsletter)
app.component('top-notification', TopNotification)
app.component('newsletter-translation', BaseNewsletter)

app.component('base-select', BaseSelect)
app.component('base-actions', BaseActions)

app.component('product-card', ProductCard)

app.component('base-filters', BaseFilters)
app.component('base-filters-mobile', BaseFiltersMobile)

app.component('collection-overview-event', CollectionOverviewEvent)
// Category
app.component('recursive-collection-list', RecursiveCollectionList)

app.component('base-product-overview-sorting-options', BaseProductOverviewSortingOptions)
app.component('base-pagination', BasePagination)

// Header components
app.component('base-top-menu', BaseTopMenu)
app.component('base-social-icons', BaseSocialIcons)
app.component('base-usps', BaseUsps)
app.component('base-search-button', BaseSearchButton)
app.component('base-cart-button', BaseCartButton)
app.component('base-mobile-usps', BaseMobileUsps)
app.component('base-main-menu', BaseMainMenu)
app.component('base-mobile-menu', BaseMobileMenu)
app.component('base-mobile-menu-button', BaseMobileMenuButton)
app.component('base-logo', BaseLogo)

// Cart
app.component('base-cart-popover', BaseCartPopover)

// Search
app.component('base-search-bar', BaseSearchBar)

// Cookie bar
app.component('base-cookie-bar', BaseCookieBar)

// Blog
app.component('article-preview', ArticlePreview)
app.component('base-article', BaseArticle)
app.component('article-call-to-action-block', ArticleCallToActionBlock)
app.component('article-image-text-block', ArticleImageTextBlock)
app.component('article-title-text-block', ArticleTitleTextBlock)

// Contact
app.component('base-contact', BaseContact)

// Product detail components
app.component('product-detail-slider', ProductDetailSlider)
app.component('product-detail-info', ProductDetailInfo)
app.component('product-detail', ProductDetail)

// Shop the look
app.component('shop-the-look-product', ShopTheLookProduct)

app.component('base-language-switch', BaseLanguageSwitch)
app.mount('#app')
