<template v-if="!loading">
  <base-select :options="selectData" v-model="selectedSort" @update:model-value="updateSort" />
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';
import { translate } from '../helper-functions';


const props = defineProps({
  hasManualOption: {
    type: Boolean,
    required: true,
  },
  initial: {
    type: String,
  }
})

const manualSort = {
  id: 'manual',
  label: translate('collection.sorting.manual'),
}
const priceLowHighSort = {
  id: 'price-low',
  label: translate('collection.sorting.price_low'),
}
const priceHighLowSort = {
  id: 'price-high',
  label: translate('collection.sorting.price_high'),
}
const newestFirstSort = {
  id: 'newest-first',
  label: translate('collection.sorting.newest'),
}
const oldestFirstSort = {
  id: 'oldest-first',
  label: translate('collection.sorting.oldest'),
}
interface option {
  id: string;
  label: string;
}
let selectData: Ref<option[]> = ref([])
let selectedSort: Ref<option> = ref(newestFirstSort)
let loading: Ref<boolean> = ref(true)

onMounted(() => {
  if (props.hasManualOption) {
    selectData.value.push(manualSort)
  }

  selectData.value.push(
    newestFirstSort,
    oldestFirstSort,
    priceLowHighSort,
    priceHighLowSort
  )

  selectedSort.value = sortIdToOption(props.initial ?? '')

  loading.value = false
});

function sortIdToOption(initial: string): option {
  switch (initial) {
    case manualSort.id:
      if(!props.hasManualOption) {
        return newestFirstSort
      }

      return manualSort
    case oldestFirstSort.id:
      return oldestFirstSort
    case priceLowHighSort.id:
      return priceLowHighSort
    case priceHighLowSort.id:
      return priceHighLowSort
    default:
      return newestFirstSort
  }
}

function updateSort(v: { id: any } | null) {
  if (v === null) {
    return
  }

  const cur = new URL(window.location.href)
  cur.searchParams.set('sort', v.id as string)
  window.location.href = cur.href
}

</script>
