<template>
  <div class="o-component">
    <div class="o-container">
      <h2 class="a-h1 m-contact__title">{{ translate('contact.general.title') }}</h2>
      <div class="m-contact">
        <div class="m-contact__left">
          <div v-for="storeAddress of props.storeAddresses" class="m-contact__opening-hours">
            <h3 class="m-text__title a-h3">
              {{ storeAddress.name }}
            </h3>
            <div class="o-editor-content">
              {{ storeAddress.street }} {{ storeAddress.house_number }}{{ storeAddress.house_number_extension ? ' ' +
                storeAddress.house_number_extension : '' }}<br>
              {{ storeAddress.zipcode }} {{ storeAddress.city }}<br>
              <a :href="`mailto:${storeAddress.email}`" :title="storeAddress.email">{{ storeAddress.email }}</a><br>
              <a :href="`tel:${storeAddress.phone}`" :title="storeAddress.phone">{{ storeAddress.phone }}</a><br><br>
              <div v-html="storeAddress.note">
              </div>
              <section class="m-contact__opening-hours" v-if="storeAddress.opening_hours.length > 0">
                <strong>
                  {{ translate('contact.opening_hours.title') }}
                </strong>
                <table>
                  <tbody>
                    <tr v-for="openingHour of storeAddress.opening_hours">
                      <td>{{ openingHour.label }}</td>
                      <td>{{ openingHour.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
        <div class="m-contact__right" v-if="!contactFormAlreadySubmitted">
          <h3 class="m-text__title a-h3">
            {{ translate('contact.form.title') }}
          </h3>
          <form ref="form" class="m-contact__form" method="post" :action="props.formEndpoint" @submit="submitForm"
            autocomplete="on">

            <!-- Name -->
            <div>
              <div class="a-input__contact" :class="{ 'a-input--invalid': v$.name.$error }">
                <label class="a-input__contact" for="form_name">
                  {{ translate('contact.form.field_name_label') }}
                </label>
                <input class="a-input" type="text" name="form_name" v-model="formValues.name"
                  :placeholder="translate('contact.form.field_name_label')" @focus="loadRecaptcha"
                  @blur="v$.name.$touch()">
                <p v-if="v$.name.$errors.length > 0" class="a-input__label--error">
                  {{ v$.name.$errors[0].$message }}
                </p>
              </div>
            </div>
            <!-- Email -->
            <div>
              <div class="a-input__contact" :class="{ 'a-input--invalid': v$.email.$error }">
                <label class="a-input__contact" for="form_email">
                  {{ translate('contact.form.field_email_label') }}
                </label>
                <input class="a-input" type="email" name="form_email" v-model="formValues.email"
                  :placeholder="translate('contact.form.field_email_label')" @focus="loadRecaptcha"
                  @blur="v$.email.$touch()">
                <p v-if="v$.email.$errors.length > 0" class="a-input__label--error">
                  {{ v$.email.$errors[0].$message }}
                </p>
              </div>
            </div>
            <!-- Phone number -->
            <div>
              <div class="a-input__contact" :class="{ 'a-input--invalid': v$.phone.$error }">
                <label class="a-input__contact" for="form_phone">
                  {{ translate('contact.form.field_phone_label') }}
                </label>
                <input class="a-input" type="tel" name="form_phone"
                  :placeholder="translate('contact.form.field_phone_label')" v-model="formValues.message"
                  @focus="loadRecaptcha" @blur="v$.phone.$touch()">
                <p v-if="v$.phone.$errors.length > 0" class="a-input__label--error">
                  {{ v$.phone.$errors[0].$message }}
                </p>
              </div>
            </div>
            <!-- Message -->
            <div>
              <div class="a-input__contact" :class="{ 'a-input--invalid': v$.message.$error }">
                <label class="a-input__contact" for="form_message">
                  {{ translate('contact.form.field_comment_label') }}
                </label>
                <textarea class="a-textarea" name="form_message" cols="30" rows="10" maxlength="2000"
                  @focus="loadRecaptcha" :placeholder="translate('contact.form.field_comment_label')"
                  @blur="v$.message.$touch()">
                </textarea>
                <p v-if="v$.message.$errors.length > 0" class="a-input__label--error">
                  {{ v$.message.$errors[0].$message }}
                </p>
              </div>
            </div>
            <input type="hidden" name="b_7dfac84e9f9c202ac271ed_265328dfd53a5" value="" />
            <button class="a-btn a-btn--primary" type="submit">
              {{ translate('contact.form.button_submit') }}
            </button>
          </form>
        </div>
        <div class="u-col-start:6@md u-col-end:12@md" v-if="contactFormAlreadySubmitted">
          <h3 class="m-text__title a-h3">{{ translate('contact.form.title') }}</h3>
          <div class="o-editor-content">
            <p>
              {{ translate('contact.message.already_submitted') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { translate } from '../../helper-functions'
import { useRecaptchaStore } from '../../stores/recaptcha'
import { Ref, onMounted, ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import useVuelidate from '@vuelidate/core'
import { maxLength, required } from '../../helper/validators'
import { StoreAddress } from '../../helper/address'

interface Form {
  name: string
  email: string
  phone: string
  message: string
}

const recaptcha = useRecaptchaStore()
const loading: Ref<boolean> = ref(false)
const errorMessage: Ref<string> = ref('')
const form: Ref<HTMLFormElement | null> = ref(null)
const cookieApi = useCookies()
const contactFormCookieKey = 'contact-form-submitted'
const contactFormAlreadySubmitted: Ref<boolean> = ref(false)
const formValues: Ref<Form> = ref({
  name: '',
  email: '',
  phone: '',
  message: '',
})
const v$ = useVuelidate({
  name: {
    required,
    maxLength: maxLength(255)
  },
  email: {
    required,
    maxLength: maxLength(320)
  },
  phone: {},
  message: {
    required,
    maxLength: maxLength(2000)
  },
}, formValues)
const props = defineProps({
  storeAddresses: {
    type: Array<StoreAddress>,
    default: [],
    required: true,
  },
  formEndpoint: {
    type: String,
    default: '/form/contact',
    required: true,
  },
  recaptchaSiteKey: {
    type: String,
    default: '',
    required: true,
  }
})
function loadRecaptcha() {
  if ((props.recaptchaSiteKey ?? '').length === 0) {
    return
  }

  recaptcha.init(props.recaptchaSiteKey)
}

onMounted(() => {
  if (cookieApi.cookies.get(contactFormCookieKey) === '1') {
    errorMessage.value = translate('contact.error.already-submitted')
    contactFormAlreadySubmitted.value = true
    return
  }

  (window as any).sendNewsletterForm = submitForm
  loading.value = false
})

async function submitForm($event: Event) {
  $event.preventDefault()

  loading.value = true
  errorMessage.value = ''
  const recapthcaToken = await recaptcha.getRecaptchaToken('contactForm')
  const serializedFormData = getSerializedFormData(form.value!)

  await fetch(props.formEndpoint, {
    method: form.value!.method,
    body: serializedFormData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'g-recaptcha-token': recapthcaToken,
    },
  }).then(async (resp) => {
    if (!resp.ok || resp.headers.get('captchaError') === '1') {
      let data = await resp.json()
      errorMessage.value = data.error ?? defaultErrorMessage()
      loading.value = false
      return
    }
    // expiresInMs is set to 30 seconds.
    const expiresInMs = 1000 * 30

    cookieApi.cookies.set(contactFormCookieKey, '1', new Date(Date.now() + expiresInMs))
    contactFormAlreadySubmitted.value = true
  }).catch(resp => {
    errorMessage.value = resp.error
  }).finally(() => {
    loading.value = false
  })
}

function getSerializedFormData(formElement: HTMLFormElement): string {
  const formData = new FormData(formElement)
  let formDataRecord: Record<string, string> = {}
  formData.forEach((value: FormDataEntryValue, key: string) => {
    formDataRecord[key] = value.toString()
  })
  return new URLSearchParams(formDataRecord).toString()
}

function defaultErrorMessage(): string {
  return translate('contact.error.general')
}
</script>
