<template>
  <span ref="navTrigger"></span>
  <div class="m-product__detail__nav-sticky">
    <div class="o-container">
      <nav class="m-product-detail__nav">
        <ol class="m-product-detail__nav-list">
          <!-- CORE-1978 Frontend: sticky menu / intersect observer -->
          <li v-if="Object.keys(navItems).length > 0" v-for="value, index of navItems" class="m-product-detail__nav-item"
            :key="value.id">
            <button type="button" @click.prevent="scrollToItem(value.element!, index)">
              {{ value.title }}
            </button>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="o-container">
    <div class="m-product-detail__trigger"></div>
    <section ref="description" class="m-product-detail__section"
      :aria-label="translate('product_detail.description.area_label')"
      :data-section="translate('product_detail.description.section')">
      <h2 class="m-product-detail__section-title">
        {{ translate('product_detail.description.title') }}
      </h2>
      <div ref="descriptionField" class="m-product-detail__description o-editor-content o-editor-content--description"
        :class="{ 'open': expanded }" v-html="props.product.description"></div>
      <button v-if="partiallyHideDescription" class="m-product-detail__description-btn a-btn"
        :class="{ 'open': expanded }" type="button" @click="toggle()">
        {{ expanded ? translate('product_detail.description.hide') :
          translate('product_detail.description.show_more') }}
      </button>
    </section>
    <div class="m-product-detail__trigger"></div>
    <section ref="productInfo" class="m-product-detail__section m-product-detail__information"
      :aria-label="translate('product_detail.product_info.area_label')"
      :data-section="translate('product_detail.product_info.section')">
      <h2 class="m-product-detail__section-title">
        {{ translate('product_detail.product_info.title') }}
      </h2>
      <table class="m-product-detail__information-table">
        <tbody>
          <tr v-for="attributeValue in attributes" :key="attributeValue.internalName">
            <td>
              {{ attributeValue.name }}
            </td>
            <td v-html="attributeValue.value">
            </td>
          </tr>
          <tr v-if="displayProductSku">
            <td>
              {{ translate('product_detail.product_info.product_number') }}
            </td>
            <td>
              {{ props.product.sku }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <template v-for="[internalName, productRelationType] of Object.entries(props.productRelations).filter(([internalName, prt]) => internalName != 'color_variant' && prt.products.length > 0)" :key="internalName">
      <div class="m-product-detail__trigger"></div>
      <section :ref="(el) => {relatedProductTypeReferences[internalName] = el}">
      <h2>{{ productRelationType.name }}</h2>
      <div class="o-product-overview__products">
        <product-card v-for="product of productRelationType.products" :product="product" :key="product.ID" :display-discount-percentage="props.displayDiscountPercentage"></product-card>
      </div>
    </section>
    </template>

  </div>
</template>
<script setup lang="ts">
import { Ref, computed, nextTick, onMounted, ref, watch } from 'vue'
import { AttributeVisibility, ProductData } from '../../models/product'
import { translate } from '../../helper-functions'
import { EventProductView, EventProduct, emitEvent } from '../../helper/tracking-events'
import { lazyLoadUpdate } from '../../lazyload'

const props = defineProps({
  product: {
    type: Object as () => ProductData,
    required: true,
  },
  productRelations: {
    type: Object as () => {
      [key: string]: {
        name: string,
        products: ProductData[]
      }
    },
    default: () => ({}),
    required: true,
  },
  displayDiscountPercentage: {
    type: Boolean,
    default: false,
  },
  displayProductSku: {
    type: Boolean,
    default: true,
  }
})

const attributes = computed(() => {
  const attrs = []
  for (const [name, attributeValue] of Object.entries(props.product.attributes)) {
    if (attributeValue.visibility.includes(AttributeVisibility.Catalog) || attributeValue.visibility.includes(AttributeVisibility.CatalogGo)) {
      attrs.push({ ...attributeValue, internalName: name })
    }
  }

  return attrs
})

function scrollToItem(element: Element, key: Number) {
  if (!element) {
    return
  }

  element.scrollIntoView({ behavior: 'smooth', inline: 'start' })
}

const descriptionField: Ref<HTMLElement | null> = ref(null)
const partiallyHideDescription = computed(() => {
  if (!descriptionField.value) {
    return false
  }

  return elementHasOverflow(descriptionField.value)
})

const expanded = ref(false)

const isSticky: Ref<Boolean> = ref(false)
const description: Ref<Element | null> = ref(null)
const productInfo: Ref<Element | null> = ref(null)
const navTrigger: Ref<Element | null> = ref(null)
const relatedProductTypeReferences: Ref<{ [key: string]: Element | null | any  }> = ref({})

const navItems: Ref<Array<{ id: string; title: string; element: Element | null }>> = ref(
  [
    {
      id: 'description',
      title: translate('product_detail.description.title'),
      element: description,
    },
    {
      id: 'product_info',
      title: translate('product_detail.product_info.title'),
      element: productInfo,
    },
  ]
)

watch(() => isSticky.value, (value) => {
  if (!value) {
    document.querySelectorAll('.m-product-detail__nav-item').forEach(tab => {
      tab.classList.remove("active")
    })
  }
});

onMounted(() => {
  for (let [internalName, relations] of Object.entries(props.productRelations).filter(([internalName, prt]) => internalName != 'color_variant' && prt.products.length > 0)) {
    if (!(internalName in relatedProductTypeReferences.value)){
      relatedProductTypeReferences.value[internalName] = null
    }

    navItems.value.push({
      id: internalName,
      title: relations.name,
      element: relatedProductTypeReferences.value[internalName]
    })
  }

  emitProductDetailEvent()
  nextTick(() => {
    lazyLoadUpdate()
    handleDetailTabs()
  })
})

function emitProductDetailEvent() {
  let productRelations: { [key: string]: EventProduct[] } = {}
  for (let [key, productRelation] of Object.entries(props.productRelations)) {
    productRelations[key] = productRelation.products.map(product => formatProduct(product))
  }

  emitEvent(EventProductView, {
    product: formatProduct(props.product),
    // Relations are created by the user and can vary from this example.
    relations: productRelations,
  })
}

function formatProduct(product: ProductData): EventProduct {
  let attributes: { [key: string]: string } = {}
  for (let [key, attribute] of Object.entries(props.product.attributes)) {
    attributes[key] = attribute.value
  }

  return {
    ID: product.ID,
    name: product.name,
    variants: (product.variants ?? []).map(v => {
      let variantAttributes: { [key: string]: string } = {}
      for (let [key, attribute] of Object.entries(v.attributes)) {
        variantAttributes[key] = attribute.value
      }
      return {
        ID: v.ID,
        name: v.name,
        price: Number(v.price),
        attributes: variantAttributes,
      }
    }),
    price: Number(product.price), // The price in cents, equivalent to €59.99.
    attributes: attributes,
  }
}

function elementHasOverflow(el: HTMLElement): boolean {
  return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
}

function handleDetailTabs() {
  const tabs = document.querySelectorAll('.m-product-detail__nav-item');
  const sectionTriggers = document.querySelectorAll('.m-product-detail__trigger');

  const stickyObserver = new IntersectionObserver(entries => {
    if (entries[0].boundingClientRect.y < 0) {
      isSticky.value = true
    } else {
      isSticky.value = false
    }
  })

  const sectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {

      if (! isSticky.value) {
        return
      }
      let intersectedIndex = Array.from(sectionTriggers).indexOf(entry.target)

      if (entry.isIntersecting) {
        intersectedIndex = Array.from(sectionTriggers).indexOf(entry.target) - 1 > 0 ? Array.from(sectionTriggers).indexOf(entry.target) - 1 : 0
      }

      tabs.forEach(tab => {
        tab.classList.remove("active")
      })

      tabs[intersectedIndex].classList.add("active")
    })
  }, {
    rootMargin: '0px 0px 100% 0px',
  })

  if (navTrigger.value) {
    stickyObserver.observe(navTrigger.value)
  }

  sectionTriggers.forEach(trigger => {
    sectionObserver.observe(trigger)
  })
}

function toggle() {
  expanded.value = !expanded.value
}

</script>
