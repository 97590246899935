import { defineStore } from "pinia";
import { computed, ref, Ref } from "vue";
import { useCookies } from "vue3-cookies";

const cookieBarKey = 'cloudcommerce_cookies_enabled';

export const useCookieBar = defineStore('cookie-bar', () => {
    const loading: Ref<boolean> = ref(true);
    const showCookieBar: Ref<boolean> = ref(true);
    const cookieApi = useCookies()

    showCookieBar.value = cookieApi.cookies.get(cookieBarKey) !== '1' && cookieApi.cookies.get(cookieBarKey) !== '0';
    loading.value = false;

    function acceptCookies() {
        cookieApi.cookies.set(cookieBarKey, '1', '1y');
        window.location.reload();
        showCookieBar.value = false;
    }

    function denyCookies() {
        cookieApi.cookies.set(cookieBarKey, '0', '1y');
        window.location.reload();
        showCookieBar.value = false;
    }

    return {
        // State
        showCookieBar: computed(() => showCookieBar.value),

        // Actions
        acceptCookies, denyCookies,
    }
})
