<template >
  <li v-if="props.filterOption.active">
    <a class="a-filter" @click.prevent="collectionFiltersStore.toggleFilterOption(props.internalName, filterOption.id)">
      <div class="a-filter__label" v-text="`${props.filterName}: ${props.filterOption.name}`"></div>
      <svg class="a-icon a-icon--filter" aria-hidden="true">
        <use href="#close" />
      </svg>
    </a>
  </li>
</template>

<script setup lang="ts">
import { RawFilterOption, useCollectionFilters } from '../../stores/collection'
const collectionFiltersStore = useCollectionFilters()

const props = defineProps({
  filterName: {
    type: String,
    required: true,
  },
  internalName: {
    type: String,
    required: true,
  },
  filterOption: {
    type: Object as () => RawFilterOption,
    required: true,
  },
})

</script>
