<template>
  <button type="button" class="m-header__hamburger u-hidden@lg" aria-label="Open menu" @click="menuStore.toggleMobileMenu()">
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script setup lang="ts">
import { useMenuStore } from '../stores/menu';

const menuStore = useMenuStore();

</script>
