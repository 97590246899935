<template>
  <section class="m-notification" aria-label="Mededeling" v-if="!loading && !isClosed">
    <div class="m-notification__inner o-container o-container--header">
      <div class="m-notification__message">
        <span class="m-notification__title">{{ props.topNotification?.title }}</span>
        <div class="o-editor-content o-editor-content--notification">
          <p class="m-notification__text">
            {{ props.topNotification?.text }}
            <a class="m-notification__text" :href="props.topNotification?.url ?? '#'">{{ props.topNotification?.url_text
              ?? '' }}</a>
          </p>
        </div>
      </div>
      <button class="m-notification__close a-btn a-btn--close" type="button" @click="closeNotification()">
        {{ translate('top_notification.button.close') }}
        <svg class="a-icon a-icon--close" aria-hidden="true">
          <use href="#close" />
        </svg>
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { PropType, Ref, h, onMounted, ref } from 'vue'
import { translate } from '../helper-functions'
import { RemovableRef, useLocalStorage } from '@vueuse/core'
import { createHash } from 'crypto';
let loading: Ref<boolean> = ref(true)
let isClosed: RemovableRef<boolean>

interface TopNotification {
  title: string
  text: string
  url: string
  url_text: string
}
const props = defineProps({
  topNotification: {
    type: Object as PropType<TopNotification>,
  },
})

onMounted(() => {
  const hash = hashTopNotificationContent(props.topNotification!)
  isClosed = useLocalStorage(`top-notification.${hash}.is-closed`, false)
  loading.value = false
})

function closeNotification() {
  if (typeof isClosed === 'undefined' || isClosed === null) {
    return
  }

  isClosed.value = true
}

function hashTopNotificationContent(topNotificaton: TopNotification): number {
  const content: string = 'title:' + (topNotificaton.title ?? '') + ' text:' + (topNotificaton.text ?? '') +
    ' url_text:' + (topNotificaton.url_text ?? '') + ' url:' + (topNotificaton.url ?? '');
    let hash = 0;
    for (let i = 0, len = content.length; i < len; i++) {
        let chr = content.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
}
</script>
