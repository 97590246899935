<template v-if="!loading">
  <form class="m-product__filters">
    <template v-for="filter in Object.values(collectionFilterStore.allfilterOptions)">
      <BaseFilterFieldset :name="filter.name" :internal-name="filter.internal_name" :options="filter.options"
        :columns="filter.columns" :mobile="props.mobile" :colors="filter.type === 'Color'" />
    </template>
  </form>
</template>
<script setup lang="ts">
import BaseFilterFieldset from './BaseFilterFieldset.vue'
import { useCollectionFilters, FilterRaw } from '../../stores/collection'
import { onMounted, Ref, ref } from 'vue';

const collectionFilterStore = useCollectionFilters()

const props = defineProps({
  filters: {
    type: Array<FilterRaw>,
    required: true,
  },
  mobile: {
    type: Boolean,
    required: false,
    default: false,
  }
})

let loading: Ref<boolean> = ref(true)

onMounted(() => {
  collectionFilterStore.init(props.filters)
  loading.value = false
})

</script>
