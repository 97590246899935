<template>
  <div class="m-product__pswp-swiper">
    <div ref="slider" class="swiper">
      <div class="swiper-wrapper" id="product_detail_swiper" ref="gallery">
        <template v-for="index in Math.ceil(props.media.length / imagesPerSlide)" :key="index">
          <div class="m-product__pswp-slide swiper-slide"
            :style="`--columns: ${Math.ceil(Math.sqrt(imagesPerSlide))}; --rows: ${Math.ceil(imagesPerSlide / Math.ceil(Math.sqrt(imagesPerSlide)))}`">
            <template v-for="number in imagesPerSlide" :key="number">
              <a v-if="props.media[getNumber(index, number)]" class="m-product__pswp"
                :href="props.media[getNumber(index, number)].thumbUrl + '?width=2500&height=2500'" :data-pswp-width="2500"
                :data-pswp-height="2500" target="_blank" rel="noreferrer">
                <picture class="a-image a-image--contain" style="--padding-ratio: calc(1/1)">
                  <img class="a-image__img" :src="props.media[getNumber(index, number)].thumbUrl + '?width=5&height=5'"
                    :data-src="props.media[getNumber(index, number)].thumbUrl + '?width=725&height=725'"
                    :title="props.media[getNumber(index, number)].title" :alt="props.media[getNumber(index, number)].alt"
                    loading="lazy">
                </picture>
              </a>
            </template>
          </div>
        </template>
      </div>
      <div class="m-product-detail__navigation u-visible@md">
        <button type="button" ref="navPrev" class="a-btn a-btn--icon m-product__swiper-nav m-product__swiper-nav--left">
          <svg class="a-icon a-icon--navigation" aria-hidden="true">
            <use xlink:href="#navigation-left" />
          </svg>
        </button>
        <button type="button" ref="navNext" class="a-btn a-btn--icon m-product__swiper-nav m-product__swiper-nav--right">
          <svg class="a-icon a-icon--navigation" aria-hidden="true">
            <use xlink:href="#navigation-right" />
          </svg>
        </button>
      </div>
    </div>
    <div class="swiper-pagination product-detail-slider" ref="pagination"></div>
  </div>
</template>

<script setup lang="ts">
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import Swiper, { A11y, Lazy, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { ComponentPublicInstance, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { Media } from '../../models/product'

const imagesPerSlide = ref(4)
const swiper = ref<Swiper>()
const lightbox = ref<any>()
const gallery = ref<ComponentPublicInstance<HTMLDivElement>>()
const slider = ref<ComponentPublicInstance<HTMLDivElement>>()
const pagination = ref<ComponentPublicInstance<HTMLDivElement>>()
const navNext = ref<ComponentPublicInstance<HTMLElement>>()
const navPrev = ref<ComponentPublicInstance<HTMLElement>>()

const props = defineProps({
  media: {
    type: Array<Media>,
    default: () => [],
  },
})
onMounted(() => {
  lightbox.value = new PhotoSwipeLightbox({
    gallery: gallery.value!,
    children: 'a',
    pswpModule: () => import('photoswipe'),
  })

  imagesPerSlide.value = props.media.length <= 2 ? 1 : 4
  lightbox.value.init()
  nextTick(() => {
    swiper.value = new Swiper(slider.value as HTMLDivElement, {
      modules: [Navigation, Pagination, A11y, Lazy],
      pagination: {
        el: pagination.value as HTMLDivElement,
        type: 'bullets',
      },
      navigation: {
        nextEl: navNext.value as HTMLElement,
        prevEl: navPrev.value as HTMLElement,
      },
      a11y: {
        enabled: true,
        containerMessage: 'Merken slider',
      },
      slidesPerView: 1,
      grabCursor: true,
    })
    lightbox.value.on('change', () => {
      if (swiper.value) {
        swiper.value.slideTo(Math.floor(lightbox.value.pswp.currIndex / imagesPerSlide.value))
      }
    })
  })
})


onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy()
    lightbox.value = null
  }
})

const getNumber = (index: number, number: number) => {
  return imagesPerSlide.value * (index - 1) + (number - 1)
}
</script>
