import { defineStore } from "pinia";
import { computed, ref, Ref } from "vue";

export const useSearchStore = defineStore('search', () => {
    let searchQuery: Ref<string> = ref('')
    let searchPanelOpened: Ref<boolean> = ref(false);
    let isInitiated: Ref<boolean> = ref(false);

    function init() {
        if (isInitiated.value) {
            return
        }

        try {
            // Get search query from URL the parameter is term.
            const urlParams = new URLSearchParams(window.location.search);
            const term = urlParams.get('term');
            if (term) {
                searchQuery.value = term
            }
        } catch (e) {
            console.error('Failed to get search query from URL', e)
        }

        isInitiated.value = true
    }
    function toggleSearchPanel() {
        searchPanelOpened.value = !searchPanelOpened.value
    }

    return {
        // State
        searchPanelOpened: computed(()=> searchPanelOpened.value),
        searchQuery,

        // Actions
        init,
        toggleSearchPanel,
    }
})
